import { User } from '@auth0/auth0-react'
import { MetaData } from '../../types/MetaData'

type NavigationItem = {
    title: string
    subtitle: string
    link: string[]
    key: keyof MetaData
}

const navigationCopy: NavigationItem[] = [
    {
        title: 'Analyze',
        subtitle: `Find where STARs fit`,
        link: ['/explore-by-job-title', '/'],
        key: 'num_open_jobs',
    },
    {
        title: 'Review',
        subtitle: `Make a hiring action plan`,
        link: ['/roles-review'],
        key: 'num_roles_in_review',
    },
    {
        title: 'Benchmark',
        subtitle: `Compare to your cohort`,
        link: ['/cohort'],
        key: 'cohort_unique_postings',
    },
]

const getSubtitle = (user: User, item: NavigationItem) => {
    if (user?.ep_dev_roles?.includes('Cohort Admin')) {
        switch (item.title) {
            case 'Analyze':
                return 'Find where STARs fit in the cohort'
            case 'Review':
                return 'Make cohort hiring action plans'
            case 'Benchmark':
                return 'See cohort data'
            default:
                return item.subtitle
        }
    }
    return item.subtitle
}

export const getNavigationCopy = (user: User) => {
    return navigationCopy.map((item) => ({
        ...item,
        subtitle: getSubtitle(user, item),
    }))
}

export { navigationCopy }
