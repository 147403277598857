import { ReactNode } from 'react'
import { Card } from '@mui/material'
import styles from './TestModeCard.module.scss'

type Props = {
    children: ReactNode
    width: string
}

const TestModeCard = ({ children, width }: Props) => {
    const card = (
        <Card className={styles.card} sx={{ minWidth: width, maxWidth: width }}>
            {children}
        </Card>
    )
    return card
}

export default TestModeCard
