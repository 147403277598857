const CohortCopy = ({ date, companies }: { date?: string; companies: string[] }) => ({
    header: `What opportunities are in my cohort for skills-based hiring?`,
    data1: `total open jobs in cohort`,
    data2: `jobs that are open to STARs`,
    data3: `Top open roles in your cohort`,
    tableInfo: `Based on STARSight Pro cohort data as of ${date}. Companies include ${
        companies.length === 0 ? 'Not Available' : companies.join(', ')
    }.`,
    info: [
        `More data, better decisions. Soon.`,
        `In the coming weeks, we’ll expand this section with data based on actions taken by your and your peers.`,
        `Your input matters.`,
        `By creating action plans and data for jobs filled, you're shaping the future of recruitment in your company and cohort. These insights improve the dataset for all companies and enable better hiring decisions for all.`,
        `Make sure to archive all closed jobs in the system before the next update on 1.21.2024.`,
    ],
    infoButton: `Archive jobs that were filled or closed`,
})

export default CohortCopy
