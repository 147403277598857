export function formatNumber(num: number) {
    const n = num
    if (n > 999 && n < 1000000) {
        return (n / 1000).toFixed(0) + 'K'
    } else if (n > 1000000) {
        return (n / 1000000).toFixed(1) + 'M'
    } else return Math.round(n)
}

export function capitalizeFirst(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}
export function capitalizeWords(text: string): string {
    return text.replace(/\b\w/g, (char) => char.toUpperCase())
}

export function formatTimestamp(timestamp?: string) {
    if (timestamp === undefined || timestamp === 'None') {
        return 'Not Available'
    }
    const date = new Date(timestamp)
    const month = date.toLocaleString('en-us', { month: 'short' })
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
}

export function splitStringIntoArrays(inputString: string): string[] {
    const maxStringLength = 26
    const words = capitalizeFirst(inputString).split(' ')

    const resultArrays: string[] = []
    let currentArray: string[] = []

    for (const word of words) {
        if (currentArray.length === 0) {
            currentArray.push(word)
        } else {
            const currentLength = currentArray.join(' ').length

            if (currentLength + 1 + word.length <= maxStringLength) {
                currentArray.push(word)
            } else {
                resultArrays.push(currentArray.join(' '))
                currentArray = [word]
            }
        }
    }

    if (currentArray.length > 0) {
        resultArrays.push(currentArray.join(' '))
    }

    if (resultArrays.length > 2) {
        const modifiedArrays = resultArrays.slice(0, 2)
        modifiedArrays[1] = modifiedArrays[1].concat('...')
        return modifiedArrays
    }

    return resultArrays
}
