type TableHeaderItem = {
    text: string
    id?: keyof JobTitlesItem
    width?: string
    maxWidth?: string
}

type GeoItem = {
    msa_code: number
    msa_title: string
}

type SortItem = {
    fieldName: string
    order: 'asc' | 'desc'
}

type JobTitlesItem = {
    id: number
    occ2010: number
    job_title: string
    msa_short_title: string
    msa_code: number
    review_status: string
    unique_postings: number
    education_requirement: number
    experience_requirement_min: number
    experience_requirement_max: number
    days_posted: number
    body: string
    title_edu_postings_ba_plus: number
    local_increase_in_talent_pool_with_stars: number
    star_transitions: number
    posted: number
    actions: string
    soc5_list?: string
}

type JobTitlesData = {
    results: JobTitlesItem[]
    page: number
    page_size: number
    total: number
}

const JobTitlesCopy = {
    date: `Last updated `,
    jobFilters: [
        {
            option: 'All open jobs',
            subtext: '',
            sort: 'posted',
            order: 'asc',
        },
        {
            option: 'Hard-to-fill jobs',
            subtext: 'Longest open roles for your company',
            sort: 'posted',
            order: 'asc',
        },
        {
            option: 'Increased diversity opportunities',
            subtext: 'Largest talent increase with STARs',
            sort: 'local_increase_in_talent_pool_with_stars',
            order: 'desc',
        },
    ],
    filterCaption: 'All roles have a minimum of 5% STAR representation and a minimum wage of $20 an hour.',
    tooltip: [
        `Listed job posts have a minimum of 5% STARs working in the role nationwide with a minimum wage of $20 per hour. `,
        `These restrictions assure that skills-based candidates are set up for success.`,
    ],
}

const SnackbarCopy = ({ numRoles }: { numRoles: number }) => ({
    header: `Role moved to review`,
    subheader: `View ${numRoles} roles in review`,
})

const TableHeader: TableHeaderItem[] = [
    {
        text: 'Role',
        id: 'job_title',
    },
    { text: 'Avg. days open', id: 'posted', width: '120px', maxWidth: '120px' },
    { text: 'Requirement', id: 'title_edu_postings_ba_plus' },
    { text: 'STARs transitions/yr', id: 'star_transitions', width: '100px', maxWidth: '100px' },
    {
        text: 'Talent increase with STARs',
        id: 'local_increase_in_talent_pool_with_stars',
        width: '145px',
        maxWidth: '145px',
    },
    { text: 'Actions', id: 'review_status' },
]

export { JobTitlesCopy, SnackbarCopy, TableHeader }
export type { GeoItem, SortItem, JobTitlesItem, JobTitlesData }
