import { ChangeEvent, useEffect, useState, useMemo } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Card,
    Chip,
    Container,
    InputAdornment,
    LinearProgress,
    Link,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
    styled,
} from '@mui/material'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { visuallyHidden } from '@mui/utils'
import classNames from 'classnames'
import { JobTitlesCopy } from '../../assets/copies/job_titles_copy'
import { ResultItem, RolesReviewCopy, SortItem } from '../../assets/copies/roles_review_copy'
import Icons from '../../assets/svgs'
import Archive from '../../components/Archive/Archive'
import RolesReviewSubheader from '../../components/Header/Subheader/RolesReviewSubheader'
import InsightsFooter from '../../components/InsightsFooter/InsightsFooter'
import styles from '../../components/JobTitlesTable/JobTitlesTable.module.scss'
import { formatNumber } from '../../utils/format'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import JobDetailsPopover from '../JobDetailsPopover/JobDetailsPopover'
import JobDetailsPopoverActionPlan from '../JobDetailsPopover/JobDetailsPopoverActionPlan/JobDetailsPopoverActionPlan'
import { useGetMeta } from '../../hooks/query/useMetaQuery'
import useJobData from '../../hooks/query/useJobData'
import useRolesReviewData from '../../hooks/query/useRolesReviewData'
import useCohortsEmployers from '../../hooks/query/useCohortsEmployers'
import { useAuth0 } from '@auth0/auth0-react'
import EmployerFilter from '../EmployerFilter/EmployerFilter'
import usePersistedState from '../../hooks/useEmproyersFilters'

const TABLE_LIMIT = 20

const SelectProps = {
    style: {
        margin: 5,
        borderRadius: 16,
        backgroundColor: styles.colorGray,
    },
}

const DEFAULT_ROWS = [5, 10, 20]

const RolesReviewTable = () => {
    const { user } = useAuth0()

    // table pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_LIMIT)
    const [totalPages, setTotalPages] = useState(1)
    const [sort, setSort] = useState<SortItem>({ fieldName: 'posted', order: 'desc' })
    const [dataAvailable, setDataAvailable] = useState(true)
    const [searchValue, setSearchValue] = useState('')

    const employer_id = useMemo(() => localStorage.getItem('employer_id') || '', [])
    const [selectedEmployers, setSelectedEmployers] = usePersistedState<number[]>('rolesReviewSelectedEmployers', [])

    const params = useMemo(
        () => ({
            employer_id: [...Array.from(new Set([employer_id, ...selectedEmployers]))].join(','),
            page: currentPage,
            page_size: rowsPerPage,
            sort: sort?.fieldName,
            order: sort?.order,
            search: searchValue,
        }),
        [selectedEmployers, employer_id, currentPage, rowsPerPage, sort?.fieldName, sort?.order, searchValue]
    )

    const { data: rolesReview, isLoading } = useRolesReviewData(params)
    const { data: jobData } = useJobData(employer_id)
    const { data: meta } = useGetMeta()

    const { data: employersList } = useCohortsEmployers(employer_id)
    const filteredEmployersList = employersList
        ? employersList?.filter((employer) => employer.employer_id !== parseInt(employer_id))
        : []

    useEffect(() => {
        if (employersList && selectedEmployers.length === 0) {
            const allEmployersIds = filteredEmployersList.map((employer) => employer.employer_id)
            setSelectedEmployers(allEmployersIds)
        }
    }, [employersList, employer_id])

    // search

    const handleSearch = (value: string) => {
        setSearchValue(value)
        setCurrentPage(1)
    }

    const clearSearch = () => {
        setSearchValue('')
        setCurrentPage(1)
    }

    // archive
    const [archiveOpen, setArchiveOpen] = useState(false)
    const [archivedRole, setArchivedRole] = useState<ResultItem>()
    // popover
    const [planOpen, setPlanOpen] = useState(false)
    const handleCloseActionPlan = async () => {
        setPlanOpen(false)
    }

    const [popoverOpen, setPopoverOpen] = useState(false)
    const handlePopoverClose = () => {
        setPopoverOpen(false)
    }

    const [selectedJobTitle, setSelectedJobTitle] = useState<ResultItem>()

    const handleAddPlan = (row: ResultItem) => {
        setSelectedJobTitle(row)
        setPlanOpen(true)
    }
    // no data found
    const NoDataMessage = () => {
        return (
            <Box className={styles.noDataMessage}>
                <Typography variant="body2" fontWeight="bold">
                    Your search turned up no results
                </Typography>
                <Typography variant="body2">
                    <Link onClick={clearSearch}>Clear your search</Link> or{' '}
                    <Link href="/explore-by-job-title">try searching all jobs on the Analyze page</Link>
                </Typography>
            </Box>
        )
    }

    // popover content
    const handleClickJobTitle = (row: ResultItem) => {
        setPopoverOpen(true)
        setSelectedJobTitle({ ...row, id: row?.job_title_id, msa_short_title: row?.msa_title })
    }

    const handleOpenArchive = (row: ResultItem) => {
        setArchiveOpen(true)
        setArchivedRole(row)
    }

    const handleCloseArchive = async () => {
        setArchiveOpen(false)
    }

    // pagination

    function scrollToTop() {
        window.scrollTo(0, 350)
    }
    useEffect(() => {
        if (rolesReview) {
            setTotalRows(rolesReview.total || 0)
            setTotalPages(Math.ceil((rolesReview.total || 0) / rowsPerPage))
            setDataAvailable(rolesReview.results.length > 0)
        }
    }, [rolesReview, rowsPerPage])

    const CustomPagination = () => {
        const paginationText = (count: number) => {
            return (
                <span>
                    {' of '}
                    <span style={{ fontWeight: 'bold' }}>{count} results</span>
                </span>
            )
        }

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const rows = parseInt(event.target.value, 10)
            setRowsPerPage(rows)
            setCurrentPage(1)
            scrollToTop()
        }

        const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => {
            setCurrentPage(value)
            scrollToTop()
        }

        const CustomPaginationWrapper = styled('div')`
            .MuiTablePagination-actions {
                display: none;
            }
        `

        return (
            <CustomPaginationWrapper className={styles.pagination}>
                <TablePagination
                    component="div"
                    labelRowsPerPage="Showing"
                    labelDisplayedRows={function defaultLabelDisplayedRows({ count }) {
                        return paginationText(count)
                    }}
                    count={totalRows || 0}
                    page={currentPage - 1}
                    onPageChange={() => null}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={DEFAULT_ROWS}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={SelectProps}
                />
                <Pagination count={totalPages || 0} page={currentPage} onChange={handleChangePage} />
            </CustomPaginationWrapper>
        )
    }

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ResultItem) => {
        const isAsc = sort.fieldName === property && sort.order === 'desc'
        setSort({ fieldName: property, order: isAsc ? 'asc' : 'desc' })
        setCurrentPage(1)
    }

    function RolesReviewTableHead(props: {
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ResultItem) => void
    }) {
        const { onRequestSort } = props
        const createSortHandler = (property: keyof ResultItem) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }
        return (
            <TableHead>
                <TableRow>
                    {RolesReviewCopy.map((copy) => {
                        const isActiveSort = sort?.fieldName === copy.id

                        return (
                            <TableCell
                                key={copy.id || copy.text}
                                className={styles.header}
                                style={{
                                    width: copy.width,
                                    maxWidth: copy.maxWidth,
                                    backgroundColor: isActiveSort ? styles.selectedHeader : '',
                                }}
                                sortDirection={isActiveSort ? sort?.order : false}
                            >
                                {copy.id && (
                                    <TableSortLabel
                                        key={copy.id}
                                        active={isActiveSort}
                                        direction={isActiveSort ? sort?.order : 'desc'}
                                        onClick={createSortHandler(copy.id)}
                                    >
                                        <Box className={styles.icons}>
                                            <Typography variant="tableHeader">{copy.text}</Typography>
                                        </Box>
                                        {isActiveSort && (
                                            <Box component="span" sx={visuallyHidden}>
                                                {sort?.order === 'desc' ? 'sorted ascending' : 'sorted descending'}
                                            </Box>
                                        )}
                                    </TableSortLabel>
                                )}
                                {!copy.id && (
                                    <Box className={styles.icons}>
                                        <Typography variant="tableHeader">{copy.text}</Typography>
                                    </Box>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        )
    }

    const RolesReviewTableRows = ({ row }: { row: ResultItem }) => {
        const highlighted = row.actions_count > 0
        const [hover, setHover] = useState(false)

        const rowClasses = classNames(styles.row, {
            [styles.highlightedRow]: highlighted,
        })

        const iconClasses = classNames(styles.tooltipIcon, {
            [styles.iconHidden]: !hover,
            [styles.iconVisible]: hover,
        })

        return (
            <TableRow
                key={row.id}
                className={rowClasses}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor: sort?.fieldName === 'job_title' && !highlighted ? styles.selectedCell : '',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Link component="button" onClick={() => handleClickJobTitle(row)}>
                                <Typography variant="body2" className={styles.jobTitle}>
                                    {row.job_title}
                                </Typography>
                            </Link>

                            <Typography variant="caption">{row.msa_title}</Typography>
                        </Box>
                        <LightTooltip
                            title={
                                <Box className={styles.tooltip}>
                                    <strong>{row.job_title}</strong>
                                    <br />
                                    SOC # {row.soc5_list}
                                </Box>
                            }
                            placement="bottom-start"
                        >
                            <InfoOutlinedIcon className={iconClasses} data-testid={`info-icon-${row.id}`} />
                        </LightTooltip>
                    </Box>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{ backgroundColor: sort?.fieldName === 'posted' && !highlighted ? styles.selectedCell : '' }}
                >
                    <Typography variant="body2">{row.days_posted}</Typography>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor:
                            sort?.fieldName === 'title_edu_postings_ba_plus' && !highlighted ? styles.selectedCell : '',
                    }}
                >
                    <Chip
                        size="small"
                        className={row.title_edu_postings_ba_plus ? styles.degreeRequired : ''}
                        label={
                            row.title_edu_postings_ba_plus ? (
                                <Typography variant="caption" fontWeight="bold" className={styles.text}>
                                    {'Degree'}
                                </Typography>
                            ) : (
                                <Typography variant="caption" fontWeight="bold" className={styles.text}>
                                    {'None Specified'}
                                </Typography>
                            )
                        }
                    />
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor:
                            sort?.fieldName === 'star_transitions' && !highlighted ? styles.selectedCell : '',
                    }}
                >
                    <Typography variant="body2">{formatNumber(row.star_transitions * 1000)}</Typography>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor:
                            sort?.fieldName === 'local_increase_in_talent_pool_with_stars' && !highlighted
                                ? styles.selectedCell
                                : '',
                    }}
                >
                    <Typography variant="body2">{`${Math.round(
                        row.local_increase_in_talent_pool_with_stars
                    )}%`}</Typography>
                </TableCell>
                <TableCell className={styles.actionCell}>
                    <Link component="button" className={styles.icons} onClick={() => handleAddPlan(row)}>
                        {row.actions_count > 0 ? (
                            <>
                                <Icons.SuccessIcon className={styles.warningIcon} />
                                <Typography variant="body2">{row.actions_count} actions</Typography>
                            </>
                        ) : (
                            <>
                                <Icons.CheckIcon />
                                <Typography variant="body2">Add plan</Typography>
                            </>
                        )}
                    </Link>
                </TableCell>
                <TableCell className={styles.cell}>
                    <Chip
                        clickable
                        variant="outlined"
                        size="small"
                        className={styles.actions}
                        label={<Typography variant="button">Update</Typography>}
                        onClick={() => handleOpenArchive(row)}
                        data-testid={`archive button ${row.id}`}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <div>
            <RolesReviewSubheader />
            <Container className={styles.container}>
                <Card className={styles.card}>
                    <Box className={styles.cardHeader}>
                        <Box className={styles.cardFilters}>
                            {user?.ep_dev_roles.includes('Cohort Admin') && (
                                <EmployerFilter
                                    employers={filteredEmployersList || []}
                                    selectedEmployers={selectedEmployers}
                                    onChange={(selected) => setSelectedEmployers(selected)}
                                />
                            )}
                        </Box>
                        <Box className={styles.cardHeaderElements}>
                            <Typography variant="body2">
                                {JobTitlesCopy.date} {meta?.employer_date_last_processed}
                            </Typography>
                            <CustomTooltip />
                            <TextField
                                label="Search table..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                type="search"
                                size="small"
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                                data-testid="search"
                            />
                        </Box>
                    </Box>
                    {isLoading ? (
                        <LinearProgress color="secondary" />
                    ) : (
                        <div>
                            <TableContainer className={styles.tableContainer}>
                                <Table>
                                    <RolesReviewTableHead onRequestSort={handleRequestSort} />
                                    {dataAvailable && (
                                        <TableBody>
                                            {rolesReview?.results.map((item: ResultItem) => (
                                                <RolesReviewTableRows row={item} key={item.id} />
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            {!dataAvailable ? <NoDataMessage /> : <CustomPagination />}
                        </div>
                    )}
                    {popoverOpen && <JobDetailsPopover item={selectedJobTitle} handleClose={handlePopoverClose} />}
                    {archiveOpen && <Archive job={archivedRole} handleClose={handleCloseArchive} />}
                    {planOpen && (
                        <JobDetailsPopoverActionPlan
                            job_title={selectedJobTitle?.job_title || ''}
                            job_title_id={selectedJobTitle?.job_title_id}
                            handleClose={handleCloseActionPlan}
                        />
                    )}
                </Card>
            </Container>
            <InsightsFooter />
        </div>
    )
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F5F5F7',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))

export default RolesReviewTable
