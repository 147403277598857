import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Typography, Snackbar, SnackbarContent, Box, IconButton } from '@mui/material'
import Icons from '../../../assets/svgs'
import styles from './ActionPlanToast.module.scss'

const ActionPlanToast = ({ handleClose }: { handleClose: () => void }) => {
    const Message = (
        <Box className={styles.message}>
            <Typography variant="body2" color="black" fontWeight="bold" className={styles.icon}>
                <Icons.InfoIcon className={styles.infoIcon} /> Action plan created.
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
                <HighlightOffOutlinedIcon fontSize="medium" color="inherit" />
            </IconButton>
        </Box>
    )

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
            <SnackbarContent style={{ backgroundColor: 'white' }} className={styles.snackbar} message={Message} />
        </Snackbar>
    )
}

export default ActionPlanToast
