import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    cohort_id: string
    employer_id: string
}

export const fetchCohortInsights = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/cohort_insights/get_cohort_insights', config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}
