import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchFilters } from '../../api/fetchFilters'

const useJobData = (employer_id: string) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery({
        queryKey: ['jobData', employer_id],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const params = { employer_id }
            return fetchFilters(token, params)
        },
        enabled: !!employer_id,
    })
}

export default useJobData
