import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Card, Chip, Container, Divider, Typography } from '@mui/material'
import { fetchCohortInsights } from '../../api/fetchCohortInsights'
import { fetchCohortOpportunities } from '../../api/fetchCohortOpportunities'
import CohortCopy from '../../assets/copies/cohort_copy'
import Icons from '../../assets/svgs'
import BarChart from '../../components/Charts/BarChart'
import CohortInsightsHeader from '../../components/Header/Subheader/CohortInsightsHeader'
import styles from './CohortPage.module.scss'
import { useGetMeta } from '../../hooks/query/useMetaQuery'

type CohortInsightsItem = {
    job_title: string
    unique_postings: number
    title_edu_postings_ba_plus_pct: number
    cohort_id: number
}

type CohortOpportunitiesItem = {
    num_companies: number
    pct_stars: number
    cohort_unique_postings: number
    cohort_id: number
}

function CohortPage() {
    const [cohortInsights, setCohortInsights] = useState<CohortInsightsItem[]>()
    const [cohortOpportunities, setCohortOpportunities] = useState<CohortOpportunitiesItem>()
    const { getAccessTokenSilently } = useAuth0()
    // metadata
    const { data: meta } = useGetMeta()

    const employer_id = localStorage.getItem('employer_id') || ''

    const handleClick = () => {
        window.location.href = '/roles-review'
    }

    useEffect(() => {
        const fetchCohortData = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const params = { cohort_id: localStorage.getItem('cohort_id') || '', employer_id: employer_id }
            const insightsData = await fetchCohortInsights(token, params)
            setCohortInsights(insightsData)
            const opportunitiesData = await fetchCohortOpportunities(token, params)
            setCohortOpportunities(opportunitiesData)
        }
        fetchCohortData()
    }, [getAccessTokenSilently])

    const params = {
        date: meta?.cohort_date_last_processed,
        companies: meta?.employers_in_cohort || [],
    }
    const transformData = (data: CohortInsightsItem[]) => {
        const newData = data.map((item) => ({
            label: [item.job_title, `Degree required ${Math.round(item.title_edu_postings_ba_plus_pct * 100)}%`],
            data: item.unique_postings,
        }))
        return newData
    }
    return (
        <>
            <CohortInsightsHeader
                num_companies={cohortOpportunities?.num_companies}
                total_jobs={cohortOpportunities?.cohort_unique_postings}
            />
            <Container className={styles.container}>
                <Card className={styles.card}>
                    <Typography variant="h6">{CohortCopy(params).header}</Typography>
                    <Box className={styles.header}>
                        <Box className={styles.metrics}>
                            <Typography variant="h3">{cohortOpportunities?.cohort_unique_postings}</Typography>
                            <Typography variant="body2">{CohortCopy(params).data1}</Typography>
                        </Box>
                        <Box className={styles.metrics}>
                            <Typography variant="h3">{cohortOpportunities?.pct_stars}</Typography>
                            <Typography variant="body2">{CohortCopy(params).data2}</Typography>
                        </Box>
                    </Box>
                    <Box className={styles.content}>
                        <Box height={300} width={650}>
                            <Typography variant="body2" fontWeight="bold">
                                {CohortCopy(params).data3}
                            </Typography>
                            <Divider />
                            <BarChart
                                inputData={transformData(cohortInsights || [])}
                                title={CohortCopy(params).data3}
                                color="orange"
                                xLabel=""
                                barThickness={15}
                            />
                        </Box>
                        <Box className={styles.support}>
                            <Typography variant="h5" fontWeight="bold">
                                {CohortCopy(params).info[0]}
                            </Typography>
                            <Typography variant="body2">{CohortCopy(params).info[1]}</Typography>
                            <Typography variant="h5" fontWeight="bold">
                                {CohortCopy(params).info[2]}
                            </Typography>
                            <Typography variant="body2">{CohortCopy(params).info[3]}</Typography>
                            <Chip
                                className={styles.chip}
                                variant="outlined"
                                label={<Typography variant="button">{CohortCopy(params).infoButton}</Typography>}
                                onClick={handleClick}
                                data-testid="info-button"
                            />
                        </Box>
                    </Box>
                    <Box className={styles.tableInfo}>
                        <Icons.InfoIcon />
                        <Typography variant="body2">{CohortCopy(params).tableInfo}</Typography>
                    </Box>
                </Card>
            </Container>
        </>
    )
}

export default CohortPage
