import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link as RouterLink } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Card,
    Chip,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    ListSubheader,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    Snackbar,
    SnackbarContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
    styled,
    Button,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import classNames from 'classnames'
import { fetchFilters } from '../../api/fetchFilters'
import {
    GeoItem,
    JobTitlesCopy,
    JobTitlesItem,
    SnackbarCopy,
    SortItem,
    TableHeader,
} from '../../assets/copies/job_titles_copy'
import Icons from '../../assets/svgs'
import { formatNumber } from '../../utils/format'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import JobTitlesSubheader from '../Header/Subheader/JobTitlesSubheader'
import InsightsFooter from '../InsightsFooter/InsightsFooter'
import { LightTooltip } from '../RolesReviewTable/RolesReviewTable'
import styles from './JobTitlesTable.module.scss'
import { useAddRoleToReview } from '../../hooks/query/useAddRoleToReview'
import { useGetMeta } from '../../hooks/query/useMetaQuery'
import useJobTitles from '../../hooks/query/useJobTitles'
import useCohortsEmployers from '../../hooks/query/useCohortsEmployers'
import EmployerFilter from '../EmployerFilter/EmployerFilter'
import usePersistedState from '../../hooks/useEmproyersFilters'

const TABLE_LIMIT = 20

const DEFAULT_ROWS = [5, 10, 20]

const ITEM_HEIGHT = 60
const ITEM_PADDING_TOP = 8
const MENU_WIDTH = 305

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: MENU_WIDTH,
            marginLeft: 45,
        },
    },
}

const SelectProps = {
    style: {
        margin: 5,
        borderRadius: 16,
        backgroundColor: styles.colorGray,
    },
}

const JobTitlesTable = () => {
    const employer_id = useMemo(() => localStorage.getItem('employer_id') || '', [])
    const { getAccessTokenSilently, user } = useAuth0()
    const [geoData, setGeoData] = useState<GeoItem[]>([])
    const [jobData, setJobData] = useState([])
    const [selectedGeo, setSelectedGeo] = useState<GeoItem>()
    const [isLoading, setIsLoading] = useState(true)
    // table pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalRows, setTotalRows] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_LIMIT)
    const [totalPages, setTotalPages] = useState(1)
    const [sort, setSort] = useState<SortItem>({ fieldName: 'posted', order: 'desc' })
    const [dataAvailable, setDataAvailable] = useState(true)
    const [reviewLoadingId, setReviewLoadingId] = useState<number | null>(null)
    const [selectedEmployers, setSelectedEmployers] = usePersistedState<number[]>('jobTitlesSelectedEmployers', [])

    // alert
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // search
    const [searchValue, setSearchValue] = useState('')

    const { data: meta, isLoading: isLoadingMeta } = useGetMeta()

    const addRoleToReviewMutation = useAddRoleToReview()

    // add role to in review
    const handleClickReview = async (row: JobTitlesItem) => {
        setReviewLoadingId(row.id)
        setSnackbarOpen(false)
        const params = {
            job_title_id: row.id,
            employer_id: employer_id,
        }
        // TODO: update actions selected
        const data = {
            actions_selected: [],
        }

        addRoleToReviewMutation.mutate(
            { params, data },
            {
                onSuccess: () => {
                    setSnackbarOpen(true)
                },
                onError: (error) => {
                    console.log('Error adding role to review:', error)
                },
            }
        )
    }

    // search
    const handleSearch = (value: string) => {
        setSearchValue(value)
        setCurrentPage(1)
    }

    const clearSearch = () => {
        setSearchValue('')
        setDataAvailable(true)
    }

    // no data found
    const NoDataMessage = () => {
        return (
            <Box className={styles.noDataMessage}>
                <Typography variant="body2" fontWeight="bold">
                    Your search turned up no results
                </Typography>
                <Typography variant="body2">
                    <Link onClick={clearSearch}>Clear</Link> or revise your search to see open roles.
                </Typography>
            </Box>
        )
    }

    // fetch MSA and OCC data and metadata
    const fetchInitialData = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        const params = { employer_id: employer_id }
        return fetchFilters(token, params)
    }

    useEffect(() => {
        const getData = async () => {
            const fetchedData = await fetchInitialData()
            const geoFilterList = fetchedData?.geoFilterList || []
            setGeoData([{ msa_code: 0, msa_title: 'Any location' }, ...geoFilterList])
            setJobData(fetchedData?.jobFilterList)
            setSelectedGeo({ msa_code: 0, msa_title: 'Any location' })
            setIsLoading(false)
        }
        getData()
    }, [getAccessTokenSilently, employer_id])

    const params = useMemo(
        () => ({
            employer_id: [...Array.from(new Set([employer_id, ...selectedEmployers]))].join(','),
            msa_code: selectedGeo?.msa_code ?? 0,
            page: currentPage,
            page_size: rowsPerPage,
            sort: sort?.fieldName,
            order: sort?.order,
            search: searchValue,
        }),
        [
            selectedEmployers,
            employer_id,
            selectedGeo?.msa_code,
            currentPage,
            rowsPerPage,
            sort?.fieldName,
            sort?.order,
            searchValue,
        ]
    )
    const { data: jobTitlesData, isLoading: isLoadingJobTitles, isFetching: isFetchingJobs } = useJobTitles(params)

    const { data: employersList } = useCohortsEmployers(employer_id)

    const filteredEmployersList = employersList
        ? employersList?.filter((employer) => employer.employer_id !== parseInt(employer_id))
        : []

    useEffect(() => {
        if (employersList && selectedEmployers.length === 0) {
            const allEmployersIds = filteredEmployersList.map((employer) => employer.employer_id)
            setSelectedEmployers(allEmployersIds)
        }
    }, [employersList, employer_id])

    // pagination
    function scrollToTop() {
        window.scrollTo(0, 400)
    }

    useEffect(() => {
        if (jobTitlesData) {
            setTotalRows(jobTitlesData.total || 0)
            setTotalPages(Math.ceil((jobTitlesData.total || 0) / rowsPerPage))
            setDataAvailable(jobTitlesData.results.length > 0)
        }
    }, [jobTitlesData, rowsPerPage])

    const CustomPagination = () => {
        const paginationText = (count: number) => {
            return (
                <span>
                    {' of '}
                    <span style={{ fontWeight: 'bold' }}>{count} results</span>
                </span>
            )
        }

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const rows = parseInt(event.target.value, 10)
            setRowsPerPage(rows)
            setCurrentPage(1)
            scrollToTop()
        }

        const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => {
            setCurrentPage(value)
            scrollToTop()
        }

        const CustomPaginationWrapper = styled('div')`
            .MuiTablePagination-actions {
                display: none;
            }
        `

        return (
            <CustomPaginationWrapper className={styles.pagination}>
                <TablePagination
                    component="div"
                    labelRowsPerPage="Showing"
                    labelDisplayedRows={function defaultLabelDisplayedRows({ count }) {
                        return paginationText(count)
                    }}
                    count={totalRows || 0}
                    page={currentPage - 1}
                    onPageChange={() => null}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={DEFAULT_ROWS}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={SelectProps}
                />
                <Pagination count={totalPages || 0} page={currentPage} onChange={handleChangePage} />
            </CustomPaginationWrapper>
        )
    }

    // filters
    const GeoFilter = () => {
        const handleChange = (event: SelectChangeEvent) => {
            const selectedMsaTitle = event.target.value
            const selectedGeoItem = geoData.find((item) => item.msa_title === selectedMsaTitle)

            if (selectedGeoItem) {
                setSelectedGeo({
                    msa_code: selectedGeoItem.msa_code,
                    msa_title: selectedMsaTitle,
                })
            }
            setCurrentPage(1)
        }

        return (
            <FormControl size="small">
                <Select
                    renderValue={(selected) => (
                        <span data-testid="geo filter">
                            <Typography variant="body2">{selected}</Typography>
                        </span>
                    )}
                    value={selectedGeo?.msa_title}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    sx={{ borderRadius: 16, minWidth: 225 }}
                >
                    <ListSubheader>
                        <Typography variant="caption">Show job postings in...</Typography>
                    </ListSubheader>
                    {geoData.map((item) => (
                        <MenuItem key={item.msa_code} value={item.msa_title} data-testid={item.msa_title}>
                            {selectedGeo?.msa_title === item.msa_title ? (
                                <Box className={styles.selectedMenu}>
                                    <CheckIcon fontSize="small" />
                                    <Typography variant="body2" color="inherit">
                                        {item.msa_title}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box className={styles.unselectedMenu}>
                                    <Typography variant="body2" color="inherit">
                                        {item.msa_title}
                                    </Typography>
                                </Box>
                            )}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof JobTitlesItem) => {
        const isAsc = sort?.fieldName == property && sort.order === 'desc'
        const sortItem: SortItem = { fieldName: property, order: isAsc ? 'asc' : 'desc' }
        setSort(sortItem)
        setIsLoading(true)
        setCurrentPage(1)
    }

    // job title table components
    function JobTitlesTableHead(props: {
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof JobTitlesItem) => void
    }) {
        const { onRequestSort } = props
        const createSortHandler = (property: keyof JobTitlesItem) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }
        return (
            <TableHead>
                <TableRow>
                    {TableHeader.map((copy) => {
                        const isActiveSort = sort?.fieldName === copy.id

                        return copy.id ? (
                            <TableCell
                                key={copy.id}
                                className={styles.header}
                                style={{
                                    width: copy.width,
                                    maxWidth: copy.maxWidth,
                                    backgroundColor: sort?.fieldName === copy.id ? styles.selectedHeader : '',
                                }}
                                sortDirection={isActiveSort ? sort.order : false}
                            >
                                <TableSortLabel
                                    key={copy.id}
                                    active={isActiveSort}
                                    direction={isActiveSort ? sort.order : 'desc'}
                                    onClick={copy.id && createSortHandler(copy.id)}
                                >
                                    <Box className={styles.icons}>
                                        <Typography variant="tableHeader">{copy.text}</Typography>
                                    </Box>
                                    {isActiveSort && (
                                        <Box component="span" sx={visuallyHidden}>
                                            {sort.order === 'desc' ? 'sorted ascending' : 'sorted descending'}
                                        </Box>
                                    )}
                                </TableSortLabel>
                            </TableCell>
                        ) : (
                            <TableCell key={copy.text} className={styles.header}>
                                <Box className={styles.icons}>
                                    <Typography variant="tableHeader">{copy.text}</Typography>
                                </Box>
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        )
    }

    const JobTitlesTableRows = ({ row }: { row: JobTitlesItem }) => {
        const [hover, setHover] = useState(false)

        const iconClasses = classNames(styles.tooltipIcon, {
            [styles.iconHidden]: !hover,
            [styles.iconVisible]: hover,
        })

        return (
            <TableRow
                key={row.id}
                className={styles.row}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor: sort?.fieldName === 'job_title' ? styles.selectedCell : '',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2">{row.job_title}</Typography>

                            <Typography variant="caption">{row.msa_short_title}</Typography>
                        </Box>
                        <LightTooltip
                            title={
                                <Box className={styles.tooltip}>
                                    <strong>{row.job_title}</strong>
                                    <br />
                                    SOC # {row.soc5_list}
                                </Box>
                            }
                            placement="bottom-start"
                            data-testid={`tooltip-${row.id}`}
                        >
                            <InfoOutlinedIcon
                                className={iconClasses}
                                aria-label="info"
                                data-testid={`info-icon-${row.id}`}
                            />
                        </LightTooltip>
                    </Box>
                </TableCell>

                <TableCell
                    className={styles.cell}
                    style={{ backgroundColor: sort?.fieldName === 'posted' ? styles.selectedCell : '' }}
                >
                    <Typography variant="body2">{row.days_posted}</Typography>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor: sort?.fieldName === 'title_edu_postings_ba_plus' ? styles.selectedCell : '',
                    }}
                >
                    <Chip
                        size="small"
                        className={row.title_edu_postings_ba_plus ? styles.degreeRequired : ''}
                        label={
                            row.title_edu_postings_ba_plus ? (
                                <Typography variant="caption" fontWeight="bold" className={styles.text}>
                                    {'Degree'}
                                </Typography>
                            ) : (
                                <Typography variant="caption" fontWeight="bold" className={styles.text}>
                                    {'None Specified'}
                                </Typography>
                            )
                        }
                    />
                </TableCell>
                <TableCell className={styles.cell}>
                    <Typography variant="body2">{formatNumber(row.star_transitions * 1000)}</Typography>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor:
                            sort?.fieldName === 'local_increase_in_talent_pool_with_stars' ? styles.selectedCell : '',
                    }}
                >
                    <Typography variant="body2">{`${Math.round(
                        row.local_increase_in_talent_pool_with_stars
                    )}%`}</Typography>
                </TableCell>
                <TableCell
                    className={styles.cell}
                    style={{
                        backgroundColor: sort?.fieldName === 'review_status' ? styles.selectedCell : '',
                    }}
                >
                    <ReviewButton row={row} />
                </TableCell>
            </TableRow>
        )
    }

    // snackbar
    const ReviewSnackbar = () => {
        const copyParams = {
            numRoles: meta?.num_roles_in_review || 0,
        }

        const handleClose = () => {
            setSnackbarOpen(false)
        }
        const message = (
            <Box className={styles.snackbar}>
                <Box className={styles.snackbarHeader}>
                    <Box className={styles.icons}>
                        <Icons.SuccessIcon className={styles.successIcon} />
                        <Typography variant="body2" fontWeight="bold">
                            {SnackbarCopy(copyParams).header}
                        </Typography>
                    </Box>
                    <IconButton size="small" color="inherit" onClick={handleClose} data-testid="snackbar close">
                        <HighlightOffOutlinedIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box className={styles.snackbarSubheader}>
                    <Link href="/roles-review">
                        <Typography variant="body2">{SnackbarCopy(copyParams).subheader}</Typography>
                    </Link>
                </Box>
            </Box>
        )
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleClose}
                data-testid="review snackbar"
            >
                <SnackbarContent style={{ backgroundColor: 'white' }} message={message} />
            </Snackbar>
        )
    }

    // review button
    const ReviewButton = ({ row }: { row: JobTitlesItem }) => {
        const reviewLoading =
            (addRoleToReviewMutation.isPending || isLoadingJobTitles || isFetchingJobs) && reviewLoadingId === row.id

        const button = row.review_status.includes('View') ? (
            <Button style={{ backgroundColor: 'transparent' }}>
                <RouterLink to={'/roles-review'} className={styles.link}>
                    {' '}
                    <Typography variant="body2" fontWeight="bold" color="inherit">
                        {row.review_status}
                    </Typography>
                </RouterLink>
            </Button>
        ) : (
            <LoadingButton
                loading={reviewLoading}
                variant="outlined"
                size="small"
                className={styles.actions}
                onClick={() => handleClickReview(row)}
                data-testid="review button"
            >
                <Typography variant="button">{row.review_status}</Typography>
            </LoadingButton>
        )
        return button
    }

    return (
        <>
            <JobTitlesSubheader />
            <Container className={styles.container}>
                <Card className={styles.card}>
                    <Box className={styles.cardHeader}>
                        <Box className={styles.cardFilters}>
                            {user?.ep_dev_roles.includes('Cohort Admin') && (
                                <EmployerFilter
                                    employers={filteredEmployersList || []}
                                    selectedEmployers={selectedEmployers}
                                    onChange={(selected) => setSelectedEmployers(selected)}
                                />
                            )}
                            <GeoFilter />
                        </Box>
                        <Box className={styles.cardHeaderElements}>
                            <Typography variant="body2" data-testid="date">
                                {JobTitlesCopy.date} {meta?.employer_date_last_processed}
                            </Typography>
                            <CustomTooltip />
                            <TextField
                                data-testid="search"
                                label="Search table..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                type="search"
                                size="small"
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Box>
                    </Box>
                    {isLoading || isLoadingJobTitles ? (
                        <LinearProgress color="secondary" />
                    ) : (
                        <div>
                            <TableContainer className={styles.tableContainer}>
                                <Table>
                                    <JobTitlesTableHead onRequestSort={handleRequestSort} />
                                    {dataAvailable && (
                                        <TableBody>
                                            {jobTitlesData?.results.map((item: JobTitlesItem) => (
                                                <JobTitlesTableRows row={item} key={item.id} />
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            {!dataAvailable ? <NoDataMessage /> : <CustomPagination />}
                        </div>
                    )}
                </Card>
            </Container>
            <InsightsFooter />
            {snackbarOpen && !isLoadingMeta && !isFetchingJobs && <ReviewSnackbar />}
        </>
    )
}

export default JobTitlesTable
