import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Circle } from '@mui/icons-material'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import {
    Box,
    Button,
    Card,
    Chip,
    Divider,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import html2canvas from 'html2canvas'
import { fetchTalentPoolDemographics } from '../../../api/fetchTalentPoolDemographics'
import { fetchTalentPoolInsights } from '../../../api/fetchTalentPoolInsights'
import { JobTitleDetailsStep1Copy } from '../../../assets/copies/job_title_details_copy'
import Icons from '../../../assets/svgs'
import { capitalizeFirst, formatNumber } from '../../../utils/format'
import BarChart from '../../Charts/BarChart'
import DoughnutChart from '../../Charts/DoughnutChart'
import BottomNavigation from '../BottomNavigation/BottomNavigation'
import styles from '../JobDetailsPopover.module.scss'
import JobDetailsPopoverStep2 from '../JobDetailsPopoverStep2/JobDetailsPopoverStep2'
import { JobTitlesItem } from '../../../types/JobDetails'

type DemographicsData = {
    stars: number
    race_and_ethnicity: {
        white: number
        black: number
        asian: number
        hispanic: number
        other: number
    }
    sex: {
        male: number
        female: number
    }
}

type InsightsData = {
    starsInTalentPool: number
    increaseInTalentPoolWithStars: number
    totalUniquePostings: number
    starsInJobProfile: number | string
    eduPostingsBaPlus: number
    starsTransitions: number | string
}

const JobDetailsPopoverStep1 = ({
    item,
    handleClickLeft,
    handleClosePopover,
}: {
    item?: JobTitlesItem
    handleClickLeft: () => void
    handleClosePopover: () => void
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [clickedRight, setClickedRight] = useState(false)
    const [talentPoolInsightsRegional, setTalentPoolInsightsRegional] = useState<InsightsData>()
    const [talentPoolInsightsNational, setTalentPoolInsightsNational] = useState<InsightsData>()
    const [talentPoolDemographicsRegional, setTalentPoolDemographicsRegional] = useState<DemographicsData>()
    const [talentPoolDemographicsNational, setTalentPoolDemographicsNational] = useState<DemographicsData>()

    // export functionality
    const [exportedScreenshots, setExportedScreenshots] = useState<string[]>([])
    const popoverRef = useRef<HTMLDivElement | null>(null)

    // function to capture a screenshot
    const captureScreenshot = async () => {
        if (popoverRef.current) {
            const screenshot = await html2canvas(popoverRef.current)
            const screenshotDataUrl = screenshot.toDataURL('image/png')
            setExportedScreenshots([screenshotDataUrl])
        }
    }

    // fetch suggestedSkills
    useEffect(() => {
        const params = {
            employer_id: String(item?.employer_id) || '',
            occ2010: item?.occ2010,
            msa_code: item?.msa_code,
            job_title: item?.job_title,
            date_last_processed_to: item?.date_last_processed_to || 'null',
            date_last_processed_from: item?.date_last_processed_from || 'null',
        }
        const nationalParams = {
            employer_id: String(item?.employer_id) || '',
            occ2010: item?.occ2010,
            msa_code: 0,
            job_title: item?.job_title,
            date_last_processed_to: item?.date_last_processed_to || 'null',
            date_last_processed_from: item?.date_last_processed_from || 'null',
        }
        const getTalentPoolInsights = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const regionalData = await fetchTalentPoolInsights(token, params)
            setTalentPoolInsightsRegional(regionalData)
            const nationalData = await fetchTalentPoolInsights(token, nationalParams)
            setTalentPoolInsightsNational(nationalData)
        }
        const getTalentPoolDemographics = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const regionalData = await fetchTalentPoolDemographics(token, params)
            setTalentPoolDemographicsRegional(regionalData)
            const nationalData = await fetchTalentPoolDemographics(token, nationalParams)
            setTalentPoolDemographicsNational(nationalData)
        }
        getTalentPoolInsights()
        getTalentPoolDemographics()
    }, [getAccessTokenSilently])

    // navigation
    const handleClickRight = () => {
        setClickedRight(true)
        captureScreenshot()
    }

    const handleClickLeftNew = () => {
        setClickedRight(false)
    }

    // copy parameters
    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        talent_pool: talentPoolInsightsRegional?.increaseInTalentPoolWithStars,
        regionalPct: Math.round(talentPoolDemographicsRegional?.sex.female || 0),
        nationalPct: Math.round(talentPoolDemographicsNational?.sex.female || 0),
        employer_name: item?.employer_name,
    }

    const Info = () => {
        return (
            <Box className={styles.info}>
                <Icons.InfoIcon />
                <Typography variant="caption">{JobTitleDetailsStep1Copy(params).info}</Typography>
            </Box>
        )
    }

    const StarsMetrics = () => {
        return !talentPoolInsightsRegional ? (
            <LinearProgress color="secondary" />
        ) : (
            <Box className={styles.starsMetrics}>
                <Box className={styles.metric}>
                    <Typography variant="h3">
                        {formatNumber(talentPoolInsightsRegional?.starsInTalentPool || 0)}
                    </Typography>
                    <Typography variant="body2">{JobTitleDetailsStep1Copy(params).stat1}</Typography>
                </Box>
                <Box className={styles.metric}>
                    <Typography variant="h3">
                        {talentPoolInsightsRegional?.increaseInTalentPoolWithStars + '%'}
                    </Typography>
                    <Typography variant="body2">{JobTitleDetailsStep1Copy(params).stat2}</Typography>
                </Box>
            </Box>
        )
    }

    const JobTitleMetrics = () => {
        return (
            <Box>
                <Box className={styles.rightData}>
                    {!talentPoolInsightsNational ? (
                        <LinearProgress color="secondary" />
                    ) : (
                        <Table className={styles.table}>
                            <TableHead>
                                <TableCell>
                                    <Typography fontWeight="bold" variant="body2">
                                        {item?.job_title}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold" variant="body2">
                                        {item?.msa_short_title.split(',')[0]}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold" variant="body2">
                                        U.S.
                                    </Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {JobTitleDetailsStep1Copy(params).stat3}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsRegional?.totalUniquePostings}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsNational?.totalUniquePostings}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {JobTitleDetailsStep1Copy(params).stat4}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsRegional?.starsInJobProfile}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsNational?.starsInJobProfile}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {JobTitleDetailsStep1Copy(params).stat5}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsRegional?.eduPostingsBaPlus}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsNational?.eduPostingsBaPlus}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {JobTitleDetailsStep1Copy(params).stat6}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <b>{`${talentPoolInsightsRegional?.increaseInTalentPoolWithStars}%`}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{`${talentPoolInsightsNational?.increaseInTalentPoolWithStars}%`}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow className={styles.lastRow}>
                                    <TableCell>
                                        <Typography variant="caption">
                                            {JobTitleDetailsStep1Copy(params).stat7}
                                        </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <b>{talentPoolInsightsNational?.starsTransitions}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                </Box>
            </Box>
        )
    }

    const transformData = (barData?: unknown) => {
        const result = barData
            ? Object.entries(barData).map(([label, data]) => ({
                  label: capitalizeFirst(label),
                  data,
              }))
            : []
        return result
    }

    const BarDemographics = () => {
        return (
            <Box className={styles.barDemographics}>
                <Box className={styles.barChart}>
                    <Typography fontWeight="bold" variant="body2">
                        {JobTitleDetailsStep1Copy(params).data1Regional}
                    </Typography>
                    <Divider />
                    {talentPoolDemographicsRegional ? (
                        <BarChart
                            inputData={transformData(talentPoolDemographicsRegional?.race_and_ethnicity)}
                            title={JobTitleDetailsStep1Copy(params).data1Regional}
                            xLabel=""
                            color="mixed"
                            percent
                        />
                    ) : (
                        <LinearProgress color="secondary" />
                    )}
                </Box>
                <Box className={styles.barChart}>
                    <Typography fontWeight="bold" variant="body2">
                        {JobTitleDetailsStep1Copy(params).data1National}
                    </Typography>
                    <Divider />
                    {talentPoolDemographicsNational ? (
                        <BarChart
                            inputData={transformData(talentPoolDemographicsNational?.race_and_ethnicity)}
                            title={JobTitleDetailsStep1Copy(params).data1National}
                            xLabel=""
                            color="mixed"
                            percent
                        />
                    ) : (
                        <LinearProgress color="secondary" />
                    )}
                </Box>
            </Box>
        )
    }

    const DoughnutDemographics = () => {
        return (
            <Box className={styles.barDemographics}>
                <Box className={styles.barChart}>
                    <Typography fontWeight="bold" variant="body2">
                        {JobTitleDetailsStep1Copy(params).data2Regional}
                    </Typography>
                    <Divider />
                    {talentPoolDemographicsRegional ? (
                        <Box className={styles.chart}>
                            <DoughnutChart
                                title={JobTitleDetailsStep1Copy(params).data2Regional}
                                inputData={transformData(talentPoolDemographicsRegional?.sex)}
                                color="purple"
                                size={125}
                            />
                            <Box className={styles.chartLegend}>
                                <Typography variant="h6">
                                    {JobTitleDetailsStep1Copy(params).regionalChartTitle}
                                </Typography>
                                <Box className={styles.element}>
                                    <Circle fontSize="small" htmlColor={styles.colorPurple100} />
                                    <Typography variant="body2">Female</Typography>
                                </Box>
                                <Box className={styles.element}>
                                    <Circle fontSize="small" htmlColor={styles.colorPurple60} />
                                    <Typography variant="body2">Male</Typography>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <LinearProgress color="secondary" />
                    )}
                </Box>
                <Box className={styles.barChart}>
                    <Typography fontWeight="bold" variant="body2">
                        {JobTitleDetailsStep1Copy(params).data2National}
                    </Typography>
                    <Divider />
                    {talentPoolDemographicsNational ? (
                        <Box className={styles.chart}>
                            <DoughnutChart
                                title={JobTitleDetailsStep1Copy(params).data2National}
                                inputData={transformData(talentPoolDemographicsNational?.sex)}
                                color="purple"
                                size={125}
                            />
                            <Box className={styles.chartLegend}>
                                <Typography variant="h6">
                                    {JobTitleDetailsStep1Copy(params).nationalChartTitle}
                                </Typography>
                                <Box className={styles.element}>
                                    <Circle fontSize="small" htmlColor={styles.colorPurple100} />
                                    <Typography variant="body2">Female</Typography>
                                </Box>
                                <Box className={styles.element}>
                                    <Circle fontSize="small" htmlColor={styles.colorPurple60} />
                                    <Typography variant="body2">Male</Typography>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <LinearProgress color="secondary" />
                    )}
                </Box>
            </Box>
        )
    }

    return !clickedRight ? (
        <Box className={styles.jobDetailsStep1Main}>
            <div ref={popoverRef}>
                <Box className={styles.titleBox}>
                    <Box className={styles.title}>
                        <Chip
                            className={styles.chip}
                            label={
                                <Typography variant="button" color="inherit">
                                    1 of 4
                                </Typography>
                            }
                        />
                        <Typography variant="body2" fontWeight="bold" color="inherit">
                            {JobTitleDetailsStep1Copy(params).title}
                        </Typography>
                    </Box>
                    <Button className={styles.button} onClick={handleClosePopover} data-testid="close">
                        <HighlightOffOutlinedIcon fontSize="large" />
                    </Button>
                </Box>
                <Typography variant="h3" color="white">
                    {JobTitleDetailsStep1Copy(params).subtitle}
                </Typography>
                <Card className={styles.card}>
                    <Box className={styles.leftData}>
                        <Typography variant="body2">{JobTitleDetailsStep1Copy(params).cardHeader}</Typography>
                        <StarsMetrics />
                        <BarDemographics />
                        <DoughnutDemographics />
                        <Info />
                    </Box>
                    <JobTitleMetrics />
                </Card>
            </div>
            <BottomNavigation
                text={JobTitleDetailsStep1Copy(params).footerText}
                job_title_id={item?.id}
                action={JobTitleDetailsStep1Copy(params).action}
                handleClickLeft={handleClickLeft}
                handleClickRight={handleClickRight}
            />
        </Box>
    ) : (
        <JobDetailsPopoverStep2
            item={item}
            handleClickLeft={handleClickLeftNew}
            handleClosePopover={handleClosePopover}
            screenshots={exportedScreenshots}
        />
    )
}

export default JobDetailsPopoverStep1
