import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@mui/material'
import { fetchMeta } from '../../../api/fetchMeta'
import { rolesReviewHeader } from '../../../assets/copies/header_copy'
import Icons from '../../../assets/svgs'
import styles from './Subheader.module.scss'
import { MetaData } from '../../../types/MetaData'

const RolesReviewSubheader = () => {
    const { getAccessTokenSilently } = useAuth0()
    //TODO replace with usemetauery
    const [meta, setMeta] = useState<MetaData>()

    useEffect(() => {
        const getMeta = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const params = {
                employer_id: localStorage.getItem('employer_id') || '',
                cohort_id: localStorage.getItem('cohort_id') || '',
            }
            const fetchedData = await fetchMeta(token, params)
            setMeta(fetchedData)
        }
        getMeta()
    }, [getAccessTokenSilently])

    const params = {
        numRoles: meta?.num_roles_in_review,
    }

    return (
        <>
            <Box className={styles.subheader}>
                <Icons.RolesReviewGraphic className={styles.graphic} />
            </Box>
            <Box className={styles.text}>
                <Box className={styles.headerText}>
                    <Typography variant="h3" color="inherit">
                        {rolesReviewHeader(params).header}
                    </Typography>
                </Box>
                <Box className={styles.subheaderText}>
                    <Typography variant="h6">{rolesReviewHeader(params).subheader}</Typography>
                </Box>
            </Box>
        </>
    )
}

export default RolesReviewSubheader
