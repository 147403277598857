import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { JobTitlesCopy } from '../../assets/copies/job_titles_copy'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 400,
    },
})

const CustomTooltip = () => {
    return (
        <CustomWidthTooltip
            title={
                <Typography variant="body2" color="white">
                    <b>{JobTitlesCopy.tooltip[0]}</b>
                    {JobTitlesCopy.tooltip[1]}
                </Typography>
            }
        >
            <Typography color="inherit" variant="body2">
                Data details
            </Typography>
        </CustomWidthTooltip>
    )
}

export default CustomTooltip
