import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addRoleToReview } from '../../api/addRoleToReview'

type IParam = {
    job_title_id?: number
    action_operation?: string
    employer_id: string
}

type IData = {
    actions_selected: string[]
}

export const useAddRoleToReview = () => {
    const queryClient = useQueryClient()
    const { getAccessTokenSilently } = useAuth0()

    return useMutation({
        mutationFn: async ({ params, data }: { params: IParam; data: IData }) => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })

            return addRoleToReview(token, params, data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['meta'] })
            queryClient.invalidateQueries({ queryKey: ['jobTitles'] })
        },
    })
}
