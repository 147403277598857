import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    occ2010?: number
    employer_id: string
    date_last_processed_from: string
    date_last_processed_to: string
}

export const fetchSuggestedSkills = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/suggested-skills', config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}
