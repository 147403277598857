import { Box, IconButton } from '@mui/material'
import Icons from '../../../assets/svgs'
import styles from './ReturnTopButton.module.scss'

function ReturnTopButton() {
    return (
        <Box className={styles.container}>
            <IconButton className={styles.returnToTopButton} href="#">
                <Icons.ReturnToTopIcon />
            </IconButton>
        </Box>
    )
}

export default ReturnTopButton
