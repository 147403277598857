import { useState, useRef, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Button, Card, Chip, Link, Typography } from '@mui/material'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { addRoleToReview } from '../../../api/addRoleToReview'
import { fetchSavedActions } from '../../../api/fetchSavedActions'
import { JobTitleDetailsStep3Copy, JobTitleDetailsStep5Copy } from '../../../assets/copies/job_title_details_copy'
import Icons from '../../../assets/svgs'
import styles from '../JobDetailsPopover.module.scss'
import { JobTitlesItem } from '../../../types/JobDetails'

const JobDetailsPopoverStep5 = ({
    item,
    handleClickLeft,
    handleClosePopover,
    screenshots,
}: {
    item?: JobTitlesItem
    handleClickLeft: () => void
    handleClosePopover: () => void
    screenshots: string[]
}) => {
    // export functionality
    const [exportedScreenshots, setExportedScreenshots] = useState<string[]>(screenshots)
    const popoverRef = useRef(null)
    const { getAccessTokenSilently } = useAuth0()

    const employer_id = localStorage.getItem('employer_id') || ''
    const employer_name = localStorage.getItem('employer_name') || ''

    const [actions, setActions] = useState<string[]>([])

    const isChecked = (action: string) => {
        return actions && actions.includes(action)
    }

    // fetch actions
    useEffect(() => {
        const getActions = async () => {
            const params = {
                job_title_id: item?.id,
                employer_id: employer_id,
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const actions = await fetchSavedActions(token, params)
            setActions(actions)
        }
        getActions()
    }, [getAccessTokenSilently])

    const handleCheckboxChange = async (action: string) => {
        let selectedActions
        if (isChecked(action)) {
            selectedActions = actions.filter((a) => a !== action)
            setActions(selectedActions)
        } else {
            selectedActions = [...actions, action]
            setActions(selectedActions)
        }
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        const params = { job_title_id: item?.id, employer_id: employer_id }
        const data = { actions_selected: selectedActions }
        addRoleToReview(token, params, data)
    }

    useEffect(() => {
        setExportedScreenshots(screenshots)
    }, [screenshots])

    const handleExport = async () => {
        if (popoverRef.current) {
            const screenshot = await html2canvas(popoverRef.current)
            const screenshotDataUrl = screenshot.toDataURL('image/png')
            const allScreenshots = [...exportedScreenshots, screenshotDataUrl]
            const pdf = new jsPDF()
            allScreenshots.forEach((item, index) => {
                if (index > 0) {
                    pdf.addPage()
                }
                pdf.addImage(item, 'PNG', 10, 10, 190, 0)
            })
            pdf.save(`${employer_name} Playbook for ${item?.job_title} in ${item?.msa_short_title}.pdf`)
        }
    }

    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        employer_name: item?.employer_name,
    }

    const BottomNavigation = () => {
        return (
            <Box className={styles.hover}>
                <Box className={styles.hoverInfo}>
                    <Box className={styles.icon}>
                        <Icons.LightningIcon />
                        <Typography variant="body2">
                            <b>Ready to take action? </b>
                            {JobTitleDetailsStep5Copy.footerText}
                        </Typography>
                    </Box>
                    <Box className={styles.hoverChips}>
                        <Chip
                            clickable
                            className={styles.export}
                            label={
                                <Typography variant="body2" fontWeight="bold" color="inherit">
                                    Export pdf
                                </Typography>
                            }
                            onClick={handleExport}
                            data-testid="export"
                        />
                    </Box>
                </Box>
                <Button className={styles.hoverButton} onClick={handleClickLeft} data-testid="left">
                    <Icons.ChevronLeftIcon />
                </Button>
                <Button className={styles.hoverButton} onClick={handleClosePopover}>
                    <HighlightOffOutlinedIcon fontSize="large" />
                </Button>
            </Box>
        )
    }

    return (
        <Box className={styles.jobDetailsStep1Main}>
            <div ref={popoverRef}>
                <Box className={styles.titleBox}>
                    <Box className={styles.title}>
                        <Chip
                            className={styles.chip}
                            label={
                                <Typography variant="button" color="inherit">
                                    5 of 5
                                </Typography>
                            }
                        />
                        <Typography variant="body2" fontWeight="bold" color="inherit">
                            {JobTitleDetailsStep3Copy(params).title}
                        </Typography>
                    </Box>
                    <Button className={styles.button} onClick={handleClosePopover} data-testid="close">
                        <HighlightOffOutlinedIcon fontSize="large" />
                    </Button>
                </Box>
                <Typography variant="h3" color="white">
                    {JobTitleDetailsStep5Copy.subtitle}
                </Typography>
                <Card className={styles.step4Card}>
                    <Box className={styles.cardContent}>
                        <Box className={styles.cardContent}>
                            {JobTitleDetailsStep5Copy.actions.map((item) => (
                                <Box className={styles.actions} id={item.action}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                        onClick={() => handleCheckboxChange(item.action)}
                                    >
                                        <input
                                            type="checkbox"
                                            id={item.action}
                                            checked={isChecked(item.action)}
                                            onChange={() => handleCheckboxChange(item.action)}
                                        />
                                        {item.action}
                                    </Typography>
                                    <Box
                                        className={
                                            isChecked(item.action) ? styles.checkedDescription : styles.description
                                        }
                                    >
                                        {item.description.map((desc, index) => (
                                            <Typography key={index} variant="caption" className={styles.typography}>
                                                {desc.type === 'link' ? (
                                                    <Link href={desc.link} target="_blank" className={styles.link}>
                                                        {desc.text}
                                                    </Link>
                                                ) : (
                                                    desc.text
                                                )}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Card>
            </div>
            <BottomNavigation />
        </Box>
    )
}

export default JobDetailsPopoverStep5
