const JobTitleDetailsCopy = ({
    job_title,
    msa_title,
    date,
}: {
    job_title?: string
    msa_title?: string
    date?: string
}) => ({
    title: `Skills-based hiring strategies for ${job_title}* in ${msa_title}.`,
    subtitle: `Job data from ${date}`,
    data1: `days since posting`,
    data2: `extra candidates in STARs talent pool`,
    data3: `source jobs that talent can successfully transition from`,
})

const JobTitleDetailsStep1Copy = ({
    job_title,
    msa_title,
    talent_pool,
    regionalPct,
    nationalPct,
    employer_name = '',
}: {
    job_title?: string
    msa_title?: string
    talent_pool?: number
    regionalPct: number
    nationalPct: number
    employer_name?: string
}) => ({
    title: `${employer_name} hiring strategies for ${job_title} in ${msa_title}`,
    subtitle: `Increase the talent pool and diversity with STARs`,
    cardHeader: `STARs have proven to be successful in the ${job_title} role. By including them in the talent pool, you can access a broad and diverse set of candidates that you may not be able to reach when you include a degree requirement for this role.`,
    stat1: `qualified STARs in the talent pool`,
    stat2: `increase in talent pool when including STARs`,
    stat3: `Total posted jobs for this role`,
    stat4: `STARs currently in this role`,
    stat5: `Posts that require a 4-year degree`,
    stat6: `Increase in talent pool with STARs`,
    stat7: `STARs transitioning to this role per year`,
    data1Regional: `Races in regional STARs talent pool`,
    data1National: `Races in national STARs talent pool`,
    data2Regional: `Gender in regional STARs pool`,
    data2National: `Gender in national STARs pool`,
    regionalChartTitle: `${regionalPct}% women`,
    nationalChartTitle: `${nationalPct}% women`,
    recommendationTitle: `Take the first steps toward finding qualified STARs.`,
    recommendationBody: [
        `Assess your job posting through a skills-based lens and compare it with other companies hiring for the same role`,
        `Review untapped source jobs that have high a skills-match to this role and numerous documented transitions`,
        `Determine which hiring practices need to change to be able to source STARs talent for this role in your region`,
    ],
    info: `Talent pool and demographic data are calculated at the job profile level.`,
    footerText: `If you require a degree consider dropping it to increase the job talent pool by ${talent_pool}%.`,
    action: `Write a skills-based job description`,
})

const JobTitleDetailsStep2Copy = ({
    job_title,
    msa_title,
    employer_name,
}: {
    job_title?: string
    msa_title?: string
    employer_name?: string
}) => ({
    title: `${employer_name} hiring strategies for ${job_title} in ${msa_title}`,
    subtitle: `Explore top skills other employers list in their job postings.`,
    cardHeader: `${job_title} can be a tough job to source, but there are a number of skills that other employers have highlighted that might be beneficial to include in your new skills-based job description. Take a look at the suggestions below.`,
    data: `Skills suggestions`,
    xLabel: `occurrence in similar job descriptions`,
    jobListingSkills: `Skills listed in your open job listing`,
    info1: `Commonly highlighted skills from similar job descriptions for ${job_title}`,
    info2: `Skills are sorted from most occurences to least occurences`,
    footerText: `Revise the required and recommended skills on your job posting.`,
    action: `Evaluate and select candidates based on skills`,
})

const JobTitleDetailsStep3Copy = ({
    job_title,
    msa_title,
    employer_name,
}: {
    job_title?: string
    msa_title?: string
    employer_name?: string
}) => ({
    title: `${employer_name} hiring strategies for ${job_title} in ${msa_title}`,
    subtitle: `Jumpstart your hiring by considering these source jobs.`,
    cardHeader: `STARs develop the skills required for the ${job_title} role in jobs that you wouldn’t necessarily expect. Take a look at the insights below to get a better idea of where you might source your next ${job_title}.`,
    data1: `Jobs that prepare STARs best with relevant skills`,
    data2: `Jobs with the highest number of STARs transitions`,
    data3: `STARs in these source jobs nationally`,
    recommendationTitle: `Expand sourcing to include all qualified candidates`,
    recommendationSubtitle: `Follow these key steps to get started:`,
    recommendationBody: [
        `Recognize that low wage does not mean low skill`,
        `Use data from your own hiring to determine common source jobs`,
        `Employ a wide range of sourcing tools`,
        `Consider new and more diverse providers of talent development`,
        `Build partnerships with local employers and workforce development entities`,
    ],
    info: `Lower-wage roles with the most relevant transferrable skills for your selected job.`,
    footerText: `Expand sourcing to all qualified candidates.`,
    action: `Expand sourcing to include all qualified candidates`,
})

const JobTitleDetailsStep5Copy = {
    subtitle: `Create a plan and take these actions today.`,
    cardHeader: `Skills-based hiring actions to take:`,
    actions: [
        {
            id: 1,
            action: `Write a skills-based job description`,
            description: [
                {
                    type: 'normal',
                    text: `1. Evaluate the job description by consulting hiring managers and those \n   currently succeeding in the role\n2. Articulate the role's priorities and top responsibilities\n3. Identify 5-10 key skills needed to perform those core responsibilities\n\t\u2022 Consider including skills highlighted by other employers - see page 2\n4. Create a skills-based job posting by removing unnecessary degree \n    requirements and focusing on the top 5-10 skills\n5. Write for everyone by checking for biased language`,
                },
            ],
        },
        {
            id: 4,
            action: `Refine onboarding to maximize new hire success`,
            description: [
                {
                    type: 'normal',
                    text: `\u2022 Give new hires confidence about getting started by sharing practical\n information and making a team introduction\n\u2022 Create a sense of belonging by assigning a buddy and by helping\n them integrate into the organization's community\n\u2022 Clearly review the new hire's role and specific responsibilities to ensure\n mutual understanding`,
                },
            ],
        },
        {
            id: 2,
            action: `Expand sourcing to include all qualified candidates`,
            description: [
                {
                    type: 'normal',
                    text: `1. Adjust your sourcing strategy to include non-traditional feeder jobs that\n    have similar skills — see step 3\n2. Use data and analytics such as Opportunity at Work's `,
                },
                {
                    type: 'link',
                    text: 'Stellarsight.org',
                    link: 'https://stellarsight.org/',
                },
                {
                    type: 'normal',
                    text: `\n    tool to identify potential source jobs based on transitions made by\n    other workers. See page 3.\n3. Use data from your own hiring to identify common source jobs\n4. Widen the range of talent development providers to include\n    community colleges, bootcamps, and technical programs.\n    See: `,
                },
                {
                    type: 'link',
                    text: 'workforcealmanac.com/explore',
                    link: 'https://workforcealmanac.com/explore',
                },
            ],
        },
        {
            id: 5,
            action: `Align learning and development to support ongoing skills growth`,
            description: [
                {
                    type: 'normal',
                    text: `\u2022 Start building a flexible, customizable L&D program\n\t\u2022 Create modular trainings that target the critical skills they need\n\t\u2022 Understand the occupational and foundational skills that are\n\t   valuable to your workforce today and those it will need in the future`,
                },
            ],
        },
        {
            id: 3,
            action: `Evaluate and select candidates based on skills`,
            description: [
                {
                    type: 'normal',
                    text: `1. Check your applicant tracking system (ATS) and remove automated\n    filters that screen out applications without a degree\n2. Create a standard set of questions, criteria, scoring rubric derived from\n    the necessary skills identified for the job\n3. Offer job simulations that emulate the experience of working on the job`,
                },
            ],
        },
    ],
    footerText: `Export action plan with job details.`,
}

export {
    JobTitleDetailsCopy,
    JobTitleDetailsStep1Copy,
    JobTitleDetailsStep2Copy,
    JobTitleDetailsStep3Copy,
    JobTitleDetailsStep5Copy,
}
