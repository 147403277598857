import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    occ2010?: number
    job_title?: string
    employer_id?: string
    msa_code?: number
    date_last_processed_from: string
    date_last_processed_to: string
}

export const fetchTalentPoolDemographics = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/skills-demographics', config)
        const reshapedData = {
            stars: response?.data['stars'],
            race_and_ethnicity: {
                white: response?.data['race_and_ethnicity']['White, non-Hispanic'],
                black: response?.data['race_and_ethnicity']['Black, non-Hispanic'],
                asian: response?.data['race_and_ethnicity']['Asian, non-Hispanic'],
                hispanic: response?.data['race_and_ethnicity']['Hispanic'],
                other: response?.data['race_and_ethnicity']['Others'],
            },
            sex: {
                male: response?.data['sex']['Male'],
                female: response?.data['sex']['Female'],
            },
        }

        return reshapedData
    } catch (error) {
        console.log(error)
    }
}
