import { ReactComponent as AddBookmarkIcon } from './AddBookmarkIcon.svg'
import { ReactComponent as BackNavIcon } from './BackNavIcon.svg'
import { ReactComponent as BetaIcon } from './alpha_icon.svg'
import { ReactComponent as BookOpenIcon } from './BookOpenIcon.svg'
import { ReactComponent as BookmarkAddedYellowIcon } from './BookmarkAddedYellowIcon.svg'
import { ReactComponent as BookmarkIcon } from './BookmarkIcon.svg'
import { ReactComponent as CancelIcon } from './CancelIcon.svg'
import { ReactComponent as CheckIcon } from './CheckIcon.svg'
import { ReactComponent as ChevronDownIcon } from './ChevronDownIcon.svg'
import { ReactComponent as ChevronLeftDisabledIcon } from './ChevronLeftDisabledIcon.svg'
import { ReactComponent as ChevronLeftIcon } from './ChevronLeftIcon.svg'
import { ReactComponent as ChevronRightDisabledIcon } from './ChevronRightDisabledIcon.svg'
import { ReactComponent as ChevronRightIcon } from './ChevronRightIcon.svg'
import { ReactComponent as CloseIcon } from './CloseIcon.svg'
import { ReactComponent as CohortGraphic } from './CohortGraphic.svg'
import { ReactComponent as CompanyIcon } from './CompanyIcon.svg'
import { ReactComponent as CompassIcon } from './CompassIcon.svg'
import { ReactComponent as DownloadIcon } from './DownloadIcon.svg'
import { ReactComponent as EditIcon } from './EditIcon.svg'
import { ReactComponent as EmployerPortalLogo } from './site_logo.svg'
import { ReactComponent as ExploreIcon } from './ExploreIcon.svg'
import { ReactComponent as FacebookIcon } from './FacebookIcon.svg'
import { ReactComponent as FileCheckedIcon } from './FileCheckedIcon.svg'
import { ReactComponent as FileDownloadIcon } from './FileDownloadIcon.svg'
import { ReactComponent as FooterLogo } from './FooterLogo.svg'
import { ReactComponent as GlobeIcon } from './GlobeIcon.svg'
import { ReactComponent as GradCapIcon } from './GradCapIcon.svg'
import { ReactComponent as InfoIcon } from './InfoIcon.svg'
import { ReactComponent as JobDetailsGraphic } from './JobDetailsGraphic.svg'
import { ReactComponent as JobDetailsPopoverGraphic } from './JobDetailsPopoverGraphic.svg'
import { ReactComponent as JobProfileGraphic } from './JobProfileGraphic.svg'
import { ReactComponent as LightningIcon } from './LightningIcon.svg'
import { ReactComponent as LinkedInIcon } from './LinkedInIcon.svg'
import { ReactComponent as NavArrowIcon } from './NavArrowIcon.svg'
import { ReactComponent as ReturnToTopIcon } from './ReturnToTopIcon.svg'
import { ReactComponent as RolesReviewGraphic } from './RolesReviewGraphic.svg'
import { ReactComponent as STARFooterLogoIcon } from './STARFooterLogoIcon.svg'
import { ReactComponent as STARLogoIcon } from './STARLogoIcon.svg'
import { ReactComponent as SettingsIcon } from './SettingsIcon.svg'
import { ReactComponent as StackIcon } from './StackIcon.svg'
import { ReactComponent as SuccessIcon } from './SuccessIcon.svg'
import { ReactComponent as TrafficConeIcon } from './TrafficConeIcon.svg'
import { ReactComponent as TrendDownArrow } from './TrendDownArrow.svg'
import { ReactComponent as TrendFlatArrow } from './TrendFlatArrow.svg'
import { ReactComponent as TrendUpArrow } from './TrendUpArrow.svg'
import { ReactComponent as TwitterIcon } from './TwitterIcon.svg'
import { ReactComponent as UserIcon } from './UserIcon.svg'

export default {
    AddBookmarkIcon,
    BackNavIcon,
    BetaIcon,
    BookmarkAddedYellowIcon,
    BookmarkIcon,
    BookOpenIcon,
    CancelIcon,
    CheckIcon,
    CloseIcon,
    CohortGraphic,
    CompassIcon,
    DownloadIcon,
    EmployerPortalLogo,
    ExploreIcon,
    FacebookIcon,
    FileCheckedIcon,
    FileDownloadIcon,
    FooterLogo,
    GlobeIcon,
    GradCapIcon,
    InfoIcon,
    JobDetailsGraphic,
    JobDetailsPopoverGraphic,
    JobProfileGraphic,
    LightningIcon,
    LinkedInIcon,
    NavArrowIcon,
    SettingsIcon,
    StackIcon,
    SuccessIcon,
    TrafficConeIcon,
    ReturnToTopIcon,
    RolesReviewGraphic,
    EditIcon,
    STARLogoIcon,
    STARFooterLogoIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ChevronRightDisabledIcon,
    ChevronLeftDisabledIcon,
    UserIcon,
    CompanyIcon,
    TrendUpArrow,
    TrendFlatArrow,
    TrendDownArrow,
    TwitterIcon,
}
