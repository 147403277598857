import { Box, Chip, Typography } from '@mui/material'
import Icons from '../../assets/svgs'
import styles from './InsightsFooter.module.scss'
import useGTMEvent from '../../hooks/useGTM'
import { useNavigate } from 'react-router-dom'

const InsightsFooter = () => {
    const { sendEvent } = useGTMEvent()
    const navigate = useNavigate()
    const handleClick = () => {
        sendEvent('footer_click', { nav_item: 'view cohort insights' })
        navigate('/cohort')
    }
    return (
        <Box className={styles.insightsFooter}>
            <Box className={styles.caption}>
                <Typography variant="h5">See insights from peer companies in your cohort</Typography>
                <Chip
                    onClick={handleClick}
                    className={styles.chip}
                    label={<Typography variant="button">View cohort insights</Typography>}
                    data-testid="cohort-insights-button"
                />
            </Box>
            <Icons.STARFooterLogoIcon />
        </Box>
    )
}

export default InsightsFooter
