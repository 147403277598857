const AccountSettingsCopy = {
    MyProfileTitle: `Personal Info`,
    MyProfileSubtitle: `Some of this info is locked because it's tied to data pathways and your security profile. If you need to change locked information, please reach out for help.`,
    SecurityTitle: `Password & Authentication`,
    SecuritySubtitle: `Some of this info is locked because it's tied to data pathways and your security profile. If you need to change locked information, please reach out for help. `,
    PrivacyTitle: `Privacy Policy`,
    PrivacySubtitle: `This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Platform and tells You about Your privacy rights and how the law protects You.  We use Your Personal data to provide and improve the Platform. By using the Platform, You agree to the collection and use of information in accordance with this Privacy Policy. `,
    TermsConditionsTitle: `Terms and Conditions`,
    TermsConditionsSubtitle: `Please read these terms and conditions carefully before using Our Platform.`,
}

export { AccountSettingsCopy }
