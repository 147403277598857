import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchSpiderChartData } from '../../api/fetchSpiderChartData'

type IParam = {
    occ2010?: number
    job_title?: string
    employer_id?: string
    msa_code?: number
    date_last_processed_from: string
    date_last_processed_to: string
}

type Skill = {
    skill: string
    occ_to_skill_level: string
    occ_to_skill_score: number
    occ_from_skill_level: string
    occ_from_skill_score: number
}

type ApiResponseItem = {
    source_title: string
    skills: Skill[]
}

export type ApiResponse = ApiResponseItem[]

const useSpiderChartData = (params: IParam): UseQueryResult<ApiResponse, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery<ApiResponse, Error>({
        queryKey: ['spiderChart', params],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const safeParams = {
                ...params,
                msa_code: params.msa_code ?? 0,
                employer_id: params.employer_id || '',
            }
            return fetchSpiderChartData(token, safeParams)
        },
        enabled: !!params.employer_id,
    })
}

export default useSpiderChartData
