import { Box, Typography } from '@mui/material'
import { cohortInsightsHeader } from '../../../assets/copies/header_copy'
import Icons from '../../../assets/svgs'
import styles from './Subheader.module.scss'

const CohortInsightsHeader = ({ num_companies, total_jobs }: { num_companies?: number; total_jobs?: number }) => {
    const params = {
        numCompanies: num_companies,
        numJobs: total_jobs,
    }

    return (
        <>
            <Box className={styles.subheader}>
                <Icons.CohortGraphic className={styles.graphic} />
            </Box>
            <Box className={styles.text}>
                <Box className={styles.headerText}>
                    <Typography variant="h3" color="inherit">
                        {cohortInsightsHeader(params).header}
                    </Typography>
                </Box>
                <Box className={styles.subheaderText}>
                    <Typography variant="h6">{cohortInsightsHeader(params).subheader}</Typography>
                </Box>
            </Box>
        </>
    )
}

export default CohortInsightsHeader
