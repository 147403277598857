import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Button, Chip, LinearProgress, Popover, Typography } from '@mui/material'
import { fetchJobTitleDetails } from '../../api/fetchJobTitleDetails'
import { fetchTalentPoolDemographics } from '../../api/fetchTalentPoolDemographics'
import { fetchTalentPoolInsights } from '../../api/fetchTalentPoolInsights'
import { JobTitleDetailsCopy } from '../../assets/copies/job_title_details_copy'
import Icons from '../../assets/svgs'
import styles from './JobDetailsPopover.module.scss'
import JobDetailsPopoverStep1 from './JobDetailsPopoverStep1/JobDetailsPopoverStep1'
import { JobTitlesItem } from '../../types/JobDetails'

type JobTitleDetailsItem = {
    posted: number
    daysSincePosted: number
    dateLastProcessed: string
    untappedTalent: string | number
    unexploredSourceJobs: number
}

const JobDetailsPopover = ({ item, handleClose }: { item?: JobTitlesItem; handleClose: () => void }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [isStartClicked, setIsStartClicked] = useState(false)
    const [jobTitleDetails, setJobTitleDetails] = useState<JobTitleDetailsItem>()

    // fetch job title details
    useEffect(() => {
        const getJobTitleDetails = async () => {
            const params = {
                occ2010: item?.occ2010,
                job_title: item?.job_title,
                employer_id: String(item?.employer_id) || '',
                msa_code: item?.msa_code,
                date_last_processed_from: item?.date_last_processed_from || 'null',
                date_last_processed_to: item?.date_last_processed_to || 'null',
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedData = await fetchJobTitleDetails(token, params)
            setJobTitleDetails(fetchedData)
        }
        getJobTitleDetails()
    }, [getAccessTokenSilently])

    // preload demographics endpoint

    // fetch job title details
    useEffect(() => {
        const getDemographics = async () => {
            const params = {
                occ2010: item?.occ2010,
                job_title: item?.job_title,
                employer_id: String(item?.employer_id) || '',
                msa_code: item?.msa_code,
                date_last_processed_from: item?.date_last_processed_from || 'null',
                date_last_processed_to: item?.date_last_processed_to || 'null',
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedData = await fetchJobTitleDetails(token, params)
            setJobTitleDetails(fetchedData)
        }
        getDemographics()
        const params = {
            employer_id: String(item?.employer_id) || '',
            occ2010: item?.occ2010,
            msa_code: item?.msa_code,
            job_title: item?.job_title,
            date_last_processed_to: item?.date_last_processed_to || 'null',
            date_last_processed_from: item?.date_last_processed_from || 'null',
        }
        const nationalParams = {
            employer_id: String(item?.employer_id) || '',
            occ2010: item?.occ2010,
            msa_code: 0,
            job_title: item?.job_title,
            date_last_processed_to: item?.date_last_processed_to || 'null',
            date_last_processed_from: item?.date_last_processed_from || 'null',
        }
        const getTalentPoolInsights = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            await fetchTalentPoolInsights(token, params)
            await fetchTalentPoolInsights(token, nationalParams)
        }
        const getTalentPoolDemographics = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            await fetchTalentPoolDemographics(token, params)
            await fetchTalentPoolDemographics(token, nationalParams)
        }
        getTalentPoolInsights()
        getTalentPoolDemographics()
    }, [getAccessTokenSilently])

    // navigation

    const handleClickStart = () => {
        setIsStartClicked(true)
    }

    const handleClickLeft = () => {
        setIsStartClicked(false)
    }

    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        date: jobTitleDetails?.dateLastProcessed,
    }

    console.log(item)

    const BottomNavigation = () => {
        return (
            <Box className={styles.hover}>
                <Button className={styles.hoverStart} onClick={handleClickStart} data-testid="start">
                    <Typography color="inherit" variant="h5">
                        Start
                    </Typography>
                    <Icons.ChevronRightIcon fontSize="large" color="inherit" />
                </Button>
            </Box>
        )
    }

    const JobDetailsPopoverMain = () => {
        return (
            <Box className={styles.jobDetailsMain}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography variant="h5" color="white">
                            Report compiled for {item?.employer_name}
                        </Typography>

                        <Box>
                            <Button onClick={handleClose} data-testid="close">
                                <HighlightOffOutlinedIcon fontSize="large" className={styles.button} />
                            </Button>
                        </Box>
                    </Box>
                    <Typography className={styles.header} variant="h1" color="white">
                        {JobTitleDetailsCopy(params).title}
                    </Typography>
                </Box>
                {!jobTitleDetails ? (
                    <LinearProgress color="secondary" />
                ) : (
                    <Typography className={styles.text} variant="body2">
                        {JobTitleDetailsCopy(params).subtitle}
                    </Typography>
                )}
                {!jobTitleDetails ? (
                    <LinearProgress color="secondary" />
                ) : (
                    <Box className={styles.stats}>
                        <Box className={styles.innerStats}>
                            <Chip
                                size="small"
                                className={styles.chip}
                                label={
                                    <Typography variant="tableHeader">{jobTitleDetails?.daysSincePosted}</Typography>
                                }
                            />
                            <Typography className={styles.text} variant="tableHeader">
                                {JobTitleDetailsCopy(params).data1}
                            </Typography>
                        </Box>
                        <Box className={styles.innerStats}>
                            <Chip
                                size="small"
                                className={styles.chip}
                                label={<Typography variant="tableHeader">{jobTitleDetails?.untappedTalent}</Typography>}
                            />
                            <Typography className={styles.text} variant="tableHeader">
                                {JobTitleDetailsCopy(params).data2}
                            </Typography>
                        </Box>
                        <Box className={styles.innerStats}>
                            <Chip
                                size="small"
                                className={styles.chip}
                                label={
                                    <Typography variant="tableHeader">
                                        {jobTitleDetails?.unexploredSourceJobs}
                                    </Typography>
                                }
                            />
                            <Typography className={styles.text} variant="tableHeader">
                                {JobTitleDetailsCopy(params).data3}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <BottomNavigation />
            </Box>
        )
    }

    return (
        <Popover
            open
            slotProps={{
                paper: {
                    style: {
                        overflowX: 'auto',
                    },
                },
                root: {
                    style: {
                        top: 0,
                        left: 0,
                    },
                },
            }}
        >
            <Box className={styles.popover}>
                {!isStartClicked ? (
                    <JobDetailsPopoverMain />
                ) : (
                    <JobDetailsPopoverStep1
                        item={item}
                        handleClickLeft={handleClickLeft}
                        handleClosePopover={handleClose}
                    />
                )}
            </Box>
        </Popover>
    )
}

export default JobDetailsPopover
