import * as React from 'react'
import { Card, Box, Tabs, Tab, Divider } from '@mui/material'
import MyProfile from '../../components/AccountSettings/MyProfile/MyProfile'
import Privacy from '../../components/AccountSettings/Privacy/Privacy'
import Security from '../../components/AccountSettings/Security/Security'
import TermsConditions from '../../components/AccountSettings/TermsConditions/TermsConditions'
import ReturnTopButton from '../../components/Buttons/ReturnTopButton/ReturnTopButton'
import styles from './AccountSettingsPage.module.scss'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function AccountSettingsPage() {
    const [value, setValue] = React.useState(0)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    return (
        <div>
            <Card className={styles.card}>
                <Box sx={{ width: '100%' }}>
                    <Tabs className={styles.tabs} value={value} onChange={handleChange} aria-label="nav tabs example">
                        <Tab label="My Profile" {...a11yProps(0)} />
                        <Tab label="Security" {...a11yProps(1)} />
                        <Tab label="Privacy" {...a11yProps(2)} />
                        <Tab label="Terms & Conditions" {...a11yProps(3)} />
                    </Tabs>
                    <Divider variant="middle" />
                    <TabPanel value={value} index={0}>
                        <MyProfile />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Security />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Privacy />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TermsConditions />
                    </TabPanel>
                </Box>
                <ReturnTopButton />
            </Card>
        </div>
    )
}

export default AccountSettingsPage
