import { useAuth0 } from '@auth0/auth0-react'
import { Typography, Box, Button, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material'
import { AccountSettingsCopy } from '../../../assets/copies/account_settings_copy'
import Icons from '../../../assets/svgs'
import styles from './MyProfile.module.scss'

function MyProfile() {
    const { user } = useAuth0()

    const renderTableRow = (label: string, value: string | JSX.Element | undefined) => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <Typography variant="body2" className={styles.leftColumn}>
                    {label}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">{value}</Typography>
            </TableCell>
        </TableRow>
    )

    return (
        <div>
            <Box sx={{ display: 'flex' }} className={styles.cardHeader}>
                <Typography variant="h5">{AccountSettingsCopy.MyProfileTitle}</Typography>
                <Button className={styles.iconButton} variant="primaryContained" startIcon={<Icons.EditIcon />}>
                    <Typography variant="button" className={styles.buttonText}>
                        EDIT
                    </Typography>
                </Button>
            </Box>
            <Box sx={{ display: 'flex' }} className={styles.cardSubtitle}>
                <Typography variant="body2">{AccountSettingsCopy.MyProfileSubtitle}</Typography>
            </Box>
            <TableContainer className={styles.profileTable}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                        {renderTableRow(
                            'Profile Photo',
                            <img
                                src={user?.ep_dev_profile_photo_url}
                                alt={user?.email + ' profile photo'}
                                width="91"
                                height="91"
                                loading="lazy"
                                className={styles.profilePhoto}
                            />
                        )}
                        {renderTableRow('Name', `${user?.ep_dev_first_name} ${user?.ep_dev_last_name}`)}
                        {renderTableRow('Email', user?.email)}
                        {renderTableRow('Organization', user?.ep_dev_organization)}
                        {renderTableRow('Industry', user?.ep_dev_industry)}
                        {renderTableRow('Role', user?.ep_dev_role)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default MyProfile
