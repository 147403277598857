import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Typography, IconButton, Snackbar, SnackbarContent, Link, MenuItem, Divider } from '@mui/material'
import { addRoleToArchive } from '../../api/addRoleToArchive'
import { ArchiveCopy } from '../../assets/copies/archive_copy'
import Icons from '../../assets/svgs'
import styles from './Archive.module.scss'

type ResultItem = {
    job_title_id?: number
}

const Archive = ({ job, handleClose }: { job?: ResultItem; handleClose: () => void }) => {
    const { getAccessTokenSilently } = useAuth0()
    // snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(true)
    const [archiveReasonOpen, setArchiveReasonOpen] = useState(false)
    const [endingOpen, setEndingOpen] = useState(false)
    const employer_id = localStorage.getItem('employer_id') || ''

    const handleClickSelect = () => {
        setArchiveReasonOpen(true)
    }

    const handleCloseSnackbar = () => {
        setEndingOpen(false)
        handleClose()
    }

    const handleCloseArchiveReason = () => {
        setArchiveReasonOpen(false)
    }

    const handleReason = async (reason: string) => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        const params = {
            job_title_id: job?.job_title_id,
            employer_id: employer_id,
        }
        const body = {
            archive_reason: reason,
        }
        addRoleToArchive(token, params, body)
        setArchiveReasonOpen(false)
        setEndingOpen(true)
        setSnackbarOpen(false)
    }

    const archiveReason = (
        <Box className={styles.reasonsSnackbar}>
            <Box className={styles.reasonsHeader}>
                <Typography variant="caption">{ArchiveCopy.header}</Typography>
                <IconButton size="small" color="inherit" onClick={handleCloseArchiveReason}>
                    <HighlightOffOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
            <MenuItem className={styles.reasons} onClick={() => handleReason(ArchiveCopy.reasons[0].text)}>
                <Typography variant="body2">{ArchiveCopy.reasons[0].text}</Typography>
                <Typography variant="caption">{ArchiveCopy.reasons[0].subtext}</Typography>
            </MenuItem>
            <MenuItem className={styles.reasons} onClick={() => handleReason(ArchiveCopy.reasons[1].text)}>
                <Typography variant="body2">{ArchiveCopy.reasons[1].text}</Typography>
                <Typography variant="caption">{ArchiveCopy.reasons[1].subtext}</Typography>
            </MenuItem>
            <Divider />
            <Box className={styles.reasonsSubheader}>
                <MenuItem className={styles.reasons} onClick={() => handleReason(ArchiveCopy.reasons[2].text)}>
                    <Typography variant="body2">{ArchiveCopy.reasons[2].text}</Typography>
                    <Typography variant="caption">{ArchiveCopy.reasons[2].subtext}</Typography>
                </MenuItem>
                <MenuItem className={styles.reasons} onClick={() => handleReason(ArchiveCopy.reasons[3].text)}>
                    <Typography variant="body2">{ArchiveCopy.reasons[3].text}</Typography>
                    <Typography variant="caption">{ArchiveCopy.reasons[3].subtext}</Typography>
                </MenuItem>
            </Box>
        </Box>
    )

    const endingMessage = (
        <Box className={styles.snackbar}>
            <Box className={styles.snackbarHeader}>
                <Box className={styles.icons}>
                    <Icons.SuccessIcon className={styles.successIcon} />
                    <Typography variant="body2" fontWeight="bold">
                        {ArchiveCopy.ending}
                    </Typography>
                </Box>
                <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
                    <HighlightOffOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box className={styles.snackbarSubheader}>
                <Link className={styles.icons}>
                    <Typography variant="body2">Fill our survey</Typography>
                </Link>
            </Box>
        </Box>
    )

    const message = (
        <Box className={styles.snackbar}>
            <Box className={styles.snackbarHeader}>
                <Box className={styles.icons}>
                    <Icons.SuccessIcon className={styles.successIcon} />
                    <Typography variant="body2" fontWeight="bold">
                        {ArchiveCopy.header}
                    </Typography>
                </Box>
                <IconButton size="small" color="inherit" onClick={handleClose} data-testid="close">
                    <HighlightOffOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box className={styles.snackbarSubheader}>
                <Link onClick={handleClickSelect} className={styles.icons} data-testid="select">
                    <Typography variant="body2">{ArchiveCopy.subheader}</Typography>
                    <ExpandMoreIcon />
                </Link>
            </Box>
        </Box>
    )
    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarOpen}>
                <SnackbarContent style={{ backgroundColor: 'white' }} message={message} />
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={archiveReasonOpen}>
                <SnackbarContent style={{ backgroundColor: 'white' }} message={archiveReason} />
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={endingOpen}
                onClose={handleClose}
            >
                <SnackbarContent style={{ backgroundColor: 'white' }} message={endingMessage} />
            </Snackbar>
        </>
    )
}
export default Archive
