import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Button, Card, Chip, Divider, LinearProgress, List, ListItem, Typography } from '@mui/material'
import html2canvas from 'html2canvas'
import { fetchJobTitleSkills } from '../../../api/fetchJobTitleSkills'
import { fetchSuggestedSkills } from '../../../api/fetchSuggestedSkills'
import { JobTitleDetailsStep2Copy } from '../../../assets/copies/job_title_details_copy'
import Icons from '../../../assets/svgs'
import { formatNumber } from '../../../utils/format'
import BarChart from '../../Charts/BarChart'
import BottomNavigation from '../BottomNavigation/BottomNavigation'
import styles from '../JobDetailsPopover.module.scss'
import JobDetailsPopoverStep3 from '../JobDetailsPopoverStep3/JobDetailsPopoverStep3'
import { JobTitlesItem } from '../../../types/JobDetails'

type SkillsItem = {
    skillName: string
    occurrencePct: number
}

type JobTitleSkillsItem = {
    skills_name: string
}

const SKILLS_LIMIT = 20

const JobDetailsPopoverStep2 = ({
    item,
    handleClickLeft,
    handleClosePopover,
    screenshots,
}: {
    item?: JobTitlesItem
    handleClickLeft: () => void
    handleClosePopover: () => void
    screenshots: string[]
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [clickedRight, setClickedRight] = useState(false)
    const [suggestedSkills, setSuggestedSkills] = useState<SkillsItem[]>()
    const [jobTitleSkills, setJobTitleSkills] = useState<JobTitleSkillsItem[]>()

    // export functionality
    const [exportedScreenshots, setExportedScreenshots] = useState<string[]>(screenshots)
    const popoverRef = useRef(null)

    const employer_id = localStorage.getItem('employer_id') || ''
    // function to capture a screenshot and add it to the exportedScreenshots array
    const captureScreenshot = async () => {
        if (popoverRef.current) {
            const screenshot = await html2canvas(popoverRef.current)
            const screenshotDataUrl = screenshot.toDataURL('image/png')
            setExportedScreenshots([...exportedScreenshots, screenshotDataUrl])
        }
    }

    useEffect(() => {
        setExportedScreenshots(screenshots)
    }, [screenshots])

    // fetch suggestedSkills
    useEffect(() => {
        const getSuggestedSkills = async () => {
            const suggestedSkillsParams = {
                occ2010: item?.occ2010,
                employer_id: employer_id,
                date_last_processed_to: item?.date_last_processed_to || 'null',
                date_last_processed_from: item?.date_last_processed_from || 'null',
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedData = await fetchSuggestedSkills(token, suggestedSkillsParams)
            setSuggestedSkills(fetchedData)
        }
        const getJobTitleSkills = async () => {
            const jobTitleSkillsParams = {
                employer_id: String(item?.employer_id),
                occ2010: item?.occ2010,
                msa_code: item?.msa_code,
                job_title: item?.job_title,
                date_last_processed_to: item?.date_last_processed_to || 'null',
                date_last_processed_from: item?.date_last_processed_from || 'null',
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedData = await fetchJobTitleSkills(token, jobTitleSkillsParams)
            setJobTitleSkills(fetchedData)
        }
        getSuggestedSkills()
        getJobTitleSkills()
    }, [getAccessTokenSilently])

    // navigation
    const handleClickRight = () => {
        setClickedRight(true)
        captureScreenshot()
    }

    const handleClickLeftNew = () => {
        setClickedRight(false)
    }

    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        employer_name: item?.employer_name,
    }

    const Info = () => {
        return (
            <Box className={styles.info}>
                <Icons.InfoIcon />
                <Box className={styles.infoText}>
                    <Typography variant="caption">{JobTitleDetailsStep2Copy(params).info1}</Typography>
                    <Typography variant="caption">{JobTitleDetailsStep2Copy(params).info2}</Typography>
                </Box>
            </Box>
        )
    }

    // transform data for bar chart
    const transformData = useMemo(() => {
        return (data: SkillsItem[]) => {
            return data.map((item) => ({
                label: `${item.skillName} ${formatNumber(item.occurrencePct)}%`,
                data: Number(item.occurrencePct),
            }))
        }
    }, [suggestedSkills])

    const JobDetailsPopoverStep2Content = () => {
        return (
            <Box className={styles.jobDetailsStep1Main}>
                <div ref={popoverRef}>
                    <Box className={styles.titleBox}>
                        <Box className={styles.title}>
                            <Chip
                                className={styles.chip}
                                label={
                                    <Typography variant="button" color="inherit">
                                        2 of 4
                                    </Typography>
                                }
                            />
                            <Typography variant="body2" fontWeight="bold" color="inherit">
                                {JobTitleDetailsStep2Copy(params).title}
                            </Typography>
                        </Box>
                        <Button className={styles.button} onClick={handleClosePopover} data-testid="close">
                            <HighlightOffOutlinedIcon fontSize="large" />
                        </Button>
                    </Box>
                    <Typography variant="h3" color="white">
                        {JobTitleDetailsStep2Copy(params).subtitle}
                    </Typography>
                    <Card className={styles.card}>
                        {suggestedSkills && (
                            <Box className={styles.leftData}>
                                <Typography variant="body2">{JobTitleDetailsStep2Copy(params).cardHeader}</Typography>
                                <Typography fontWeight="bold" variant="body2">
                                    {JobTitleDetailsStep2Copy(params).data}
                                </Typography>
                                <Divider />
                                <Box height={400}>
                                    {!suggestedSkills ? (
                                        <LinearProgress color="secondary" />
                                    ) : (
                                        <BarChart
                                            inputData={transformData(suggestedSkills)}
                                            title={JobTitleDetailsStep2Copy(params).data}
                                            color="green"
                                            xLabel={JobTitleDetailsStep2Copy(params).xLabel}
                                            percent
                                        />
                                    )}
                                </Box>
                                <Info />
                            </Box>
                        )}
                        <Box className={styles.rightData}>
                            <Typography fontWeight="bold" variant="body2">
                                {JobTitleDetailsStep2Copy(params).jobListingSkills}
                            </Typography>
                            <Divider />
                            <Box className={styles.jobTitleSkillsList}>
                                {!jobTitleSkills ? (
                                    <LinearProgress color="secondary" />
                                ) : (
                                    jobTitleSkills?.slice(0, SKILLS_LIMIT).map((item) => (
                                        <List key={item.skills_name} sx={{ listStyleType: 'disc', padding: 0 }}>
                                            <ListItem className={styles.listItem}>
                                                <Typography variant="caption">{item.skills_name}</Typography>
                                            </ListItem>
                                        </List>
                                    ))
                                )}
                            </Box>
                        </Box>
                    </Card>
                </div>
                <BottomNavigation
                    text={JobTitleDetailsStep2Copy(params).footerText}
                    job_title_id={item?.id}
                    action={JobTitleDetailsStep2Copy(params).action}
                    handleClickLeft={handleClickLeft}
                    handleClickRight={handleClickRight}
                />
            </Box>
        )
    }

    return !clickedRight ? (
        <JobDetailsPopoverStep2Content />
    ) : (
        <JobDetailsPopoverStep3
            item={item}
            handleClickLeft={handleClickLeftNew}
            handleClosePopover={handleClosePopover}
            screenshots={exportedScreenshots}
        />
    )
}

export default JobDetailsPopoverStep2
