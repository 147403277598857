import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import '@fontsource/dm-sans'
import { ThemeProvider } from '@mui/material'
import Auth0ProvideWithHistory from './auth/auth0-provider-with-history'
import Layout from './layout/Layout'
import theme from './theme/theme'
import { ProtectedRoute } from './utils/ProtectedRoute'
import { store } from './utils/store'
import AccountSettingsPage from './views/AccountSettingsPage/AccountSettingsPage'
import CohortPage from './views/CohortPage/CohortPage'
import ExploreJobsPage from './views/ExploreJobsPage/ExploreJobsPage'
import RolesReviewPage from './views/RolesReviewPage/RolesReviewPage'
import TestSettingsPage from './views/TestSettingsPage/TestSettingsPage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const App = () => {
    return (
        <BrowserRouter>
            <Auth0ProvideWithHistory
                domain={process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN || ''}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
                redirectUri={window.location.origin}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
                useRefreshTokens={true}
                cacheLocation={'localstorage'}
                scope={'offline_access'}
            >
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <Layout>
                                <Routes>
                                    <Route path="/" element={<ProtectedRoute component={ExploreJobsPage} />} />
                                    <Route
                                        path="/explore-by-job-title"
                                        element={<ProtectedRoute component={ExploreJobsPage} />}
                                    />
                                    <Route
                                        path="/account-settings"
                                        element={<ProtectedRoute component={AccountSettingsPage} />}
                                    />
                                    <Route path="/cohort" element={<ProtectedRoute component={CohortPage} />} />
                                    <Route
                                        path="/roles-review"
                                        element={<ProtectedRoute component={RolesReviewPage} />}
                                    />
                                    <Route
                                        path="/test-settings"
                                        element={<ProtectedRoute component={TestSettingsPage} />}
                                    />
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                            </Layout>
                        </ThemeProvider>
                    </QueryClientProvider>
                </Provider>
            </Auth0ProvideWithHistory>
        </BrowserRouter>
    )
}
export default App
