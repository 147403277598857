import { Doughnut } from 'react-chartjs-2'
import { Box } from '@mui/material'
import { Chart, ArcElement } from 'chart.js'
import styles from './Chart.module.scss'

Chart.register(ArcElement)

const COLORS: { [key: string]: string[] } = {
    purple: [styles.colorPurple60, styles.colorPurple100],
}

type DataItem = {
    label: string
    data: number
}

const DoughnutChart = ({
    title,
    inputData,
    color,
    size,
}: {
    title: string
    inputData: DataItem[]
    color: string
    size?: number
}) => {
    const options = {
        cutout: '75%',
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    }
    const labels = inputData.map((item) => item.label)
    const data = {
        labels,
        datasets: [
            {
                data: inputData.map((item) => item.data),
                backgroundColor: COLORS[color],
            },
        ],
    }

    return (
        <Box width={size} height={size}>
            <Doughnut data-testid={title} data={data} options={options} />
        </Box>
    )
}

export default DoughnutChart
