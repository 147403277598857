import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Card, Chip, Link, Popover, Typography } from '@mui/material'
import { addRoleToReview } from '../../../api/addRoleToReview'
import { fetchSavedActions } from '../../../api/fetchSavedActions'
import { JobTitleDetailsStep5Copy } from '../../../assets/copies/job_title_details_copy'
import styles from '../JobDetailsPopover.module.scss'

const JobDetailsPopoverActionPlan = ({
    job_title,
    job_title_id,
    handleClose,
}: {
    job_title: string
    job_title_id?: number
    handleClose: () => void
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [actions, setActions] = useState<string[]>([])

    const isChecked = (action: string) => {
        return actions?.includes(action)
    }
    const employer_id = localStorage.getItem('employer_id') || ''

    // fetch actions
    useEffect(() => {
        const getActions = async () => {
            const params = {
                job_title_id: job_title_id,
                employer_id: employer_id,
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const actions = await fetchSavedActions(token, params)
            setActions(actions)
        }
        getActions()
    }, [getAccessTokenSilently])

    const handleClick = (action: string) => {
        if (actions.includes(action)) {
            setActions(
                actions.filter(function (item) {
                    return item !== action
                })
            )
        } else {
            setActions([...actions, action])
        }
    }

    const handleDone = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        const params = { job_title_id: job_title_id, employer_id: employer_id }
        const data = { actions_selected: actions, employer_id: employer_id }
        addRoleToReview(token, params, data)
        handleClose()
    }

    const ActionPlan = () => {
        return (
            <Box className={styles.jobDetailsStep1Main}>
                <Typography variant="h3" color="white">
                    {job_title} action plan
                </Typography>
                <Card className={styles.step4Card}>
                    <Box className={styles.cardContent}>
                        <Box className={styles.cardContent}>
                            {JobTitleDetailsStep5Copy.actions.map((item) => (
                                <Box className={styles.actions}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="caption"
                                        onClick={() => handleClick(item.action)}
                                    >
                                        <input
                                            type="checkbox"
                                            id={item.action}
                                            defaultChecked={isChecked(item.action)}
                                        />
                                        <label htmlFor={item.action}>{item.action}</label>
                                    </Typography>
                                    <Box
                                        className={
                                            isChecked(item.action) ? styles.checkedDescription : styles.description
                                        }
                                    >
                                        {item.description.map((desc, index) => (
                                            <Typography key={index} variant="caption" className={styles.typography}>
                                                {desc.type === 'link' ? (
                                                    <Link href={desc.link} target="_blank" className={styles.link}>
                                                        {desc.text}
                                                    </Link>
                                                ) : (
                                                    desc.text
                                                )}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.chipContainer}>
                        <Chip
                            onClick={handleClose}
                            className={styles.chip}
                            label={
                                <Typography variant="body2" fontWeight="bold">
                                    Cancel
                                </Typography>
                            }
                        />
                        <Chip
                            variant="outlined"
                            label={
                                <Typography variant="body2" fontWeight="bold">
                                    Apply
                                </Typography>
                            }
                            onClick={handleDone}
                            data-testid="done"
                        />
                    </Box>
                </Card>
            </Box>
        )
    }

    return (
        <Popover
            open
            slotProps={{
                paper: {
                    style: {
                        overflowX: 'auto',
                    },
                },
            }}
        >
            <Box className={styles.popover}>
                <ActionPlan />
            </Box>
        </Popover>
    )
}

export default JobDetailsPopoverActionPlan
