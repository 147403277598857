import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    job_title_id?: number
    action_operation?: string
    employer_id: string
}

type IData = {
    actions_selected: string[]
}

export const addRoleToReview = async (token: string, params: IParam, data: IData) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.post(api_url + '/employers/job-titles/review', data, config)
        return response
    } catch (error) {
        console.log(error)
    }
}
