import { RefAttributes, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Typography,
    FormControlLabel,
    TextField,
    Autocomplete,
    Radio,
    LinearProgress,
    Popper,
    PopperProps,
    Chip,
} from '@mui/material'
import { fetchCohorts } from '../../api/fetchCohorts'
import { fetchEmployers } from '../../api/fetchEmployers'
import { TestSettingsCopy } from '../../assets/copies/test_settings_copy'
import TestModeCard from '../../components/Cards/TestModeCard/TestModeCard'
import UserProfileBanner from '../../components/UserProfileBanner/UserProfileBanner'
import styles from './TestSettingsPage.module.scss'

type IEmployer = {
    id: string
    name: string
}

function TestSettingsPage() {
    const { getAccessTokenSilently } = useAuth0()
    const [employers, setEmployers] = useState<IEmployer[] | undefined>([])
    const [selectedEmployer, setSelectedEmployer] = useState<IEmployer>()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const handleChangeSelectedEmployer = (option: IEmployer) => {
        setSelectedEmployer(option)
    }

    const handleUpdate = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        if (selectedEmployer) {
            const params = { employer_id: selectedEmployer.id }
            const cohorts = await fetchCohorts(token, params)
            if (cohorts && cohorts.length > 0) {
                localStorage.setItem('cohort_id', cohorts[0].cohort_id)
                localStorage.setItem('cohort_name', cohorts[0].cohort_name)
            } else {
                localStorage.setItem('cohort_id', '')
                localStorage.setItem('cohort_name', '')
            }
            localStorage.setItem('employer_id', selectedEmployer.id)
            localStorage.setItem('employer_name', selectedEmployer.name)
        }
        setIsDisabled(true)
    }

    useEffect(() => {
        setIsDisabled(selectedEmployer?.id == localStorage.getItem('employer_id'))
    }, [selectedEmployer])

    useEffect(() => {
        setIsLoading(true)
        const fetchEmployersList = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })

            const employersList = await fetchEmployers(token)
            setEmployers(employersList?.employers)
            const currentEmployer = employersList?.employers.find(
                (employer: IEmployer) => employer.id == localStorage.getItem('employer_id')
            )
            if (currentEmployer) {
                setSelectedEmployer(currentEmployer)
            }
            setIsLoading(false)
        }
        fetchEmployersList()
    }, [getAccessTokenSilently])

    const CustomPopper = function (
        props: JSX.IntrinsicAttributes & Pick<PopperProps, 'open'> & RefAttributes<HTMLDivElement>
    ) {
        return <Popper {...props} />
    }

    return (
        <div>
            <Box className={styles.page}>
                <div className={styles.userProfileBanner}>
                    <UserProfileBanner />
                </div>
                {isLoading ? (
                    <LinearProgress color="secondary" />
                ) : (
                    <div style={{ width: '100%' }}>
                        <TestModeCard width={'566px'}>
                            <Box className={styles.card}>
                                <Box sx={{ display: 'flex' }} className={styles.cardHeader}>
                                    <Typography variant="h6" className={styles.titleText}>
                                        {TestSettingsCopy.title}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }} className={styles.cardSubtitle}>
                                    <Typography variant="body2" className={styles.titleText}>
                                        {TestSettingsCopy.subtitle}
                                    </Typography>
                                </Box>
                                <Box className={styles.searchContainer}>
                                    <Autocomplete
                                        open={true}
                                        fullWidth={true}
                                        selectOnFocus={true}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: '250px',
                                                position: 'fixed',
                                                backgroundColor: 'white',
                                                width: '100%',
                                                border: '1px solid ' + styles.colorNeutral200,
                                            },
                                        }}
                                        PopperComponent={CustomPopper}
                                        options={employers ? employers : []}
                                        disableCloseOnSelect
                                        disablePortal
                                        getOptionLabel={(option: IEmployer | string) =>
                                            typeof option === 'string' ? option : option.name
                                        }
                                        onChange={(event, checkedEmployer) =>
                                            checkedEmployer && handleChangeSelectedEmployer(checkedEmployer)
                                        }
                                        renderOption={(state, option) => {
                                            return (
                                                <li {...state} style={{ paddingLeft: '10px' }} key={option.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                style={{ marginRight: 10 }}
                                                                checked={option.id == selectedEmployer?.id}
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                variant="body2"
                                                                data-testid={'employer-option-' + option.id}
                                                            >
                                                                {option.name}
                                                            </Typography>
                                                        }
                                                    />
                                                </li>
                                            )
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexWrap: 'wrap',
                                                        }}
                                                    >
                                                        <SearchIcon />
                                                        <span style={{ paddingLeft: 5 }}>Search</span>
                                                    </div>
                                                }
                                                placeholder="Search"
                                            />
                                        )}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                    <Chip
                                        className={styles.buttonContainer}
                                        onClick={handleUpdate}
                                        disabled={isDisabled}
                                        data-testid="update-button"
                                        label={
                                            <Typography variant="body2" fontWeight="bold" className={styles.buttonText}>
                                                Update data
                                            </Typography>
                                        }
                                    />
                                </Box>
                            </Box>
                        </TestModeCard>
                    </div>
                )}
            </Box>
        </div>
    )
}

export default TestSettingsPage
