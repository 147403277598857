import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    cohort_id: string
    employer_id: string
}

export const fetchCohortOpportunities = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/cohort_insights/get_cohort_opportunities', config)
        const updatedResponse = {
            num_companies: response?.data.num_companies,
            pct_stars: Math.round(response?.data.pct_stars),
            cohort_unique_postings: response?.data.cohort_unique_postings,
            cohort_id: response?.data.cohort_id,
        }
        return updatedResponse
    } catch (error) {
        console.log(error)
    }
}
