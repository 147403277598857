import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    employer_id: string
}

export const fetchCohortEmployers = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/cohorts/employers-cohort', config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}
