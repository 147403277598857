type TableHeaderItem = {
    text: string
    id?: keyof ResultItem
    width?: string
    maxWidth?: string
}

type ResultItem = {
    id: number
    job_title_id: number
    job_title: string
    msa_title: string
    msa_code: number
    msa_short_title: string
    unique_postings: number
    days_posted: number
    actions: string[]
    actions_count: number
    star_transitions: number
    local_increase_in_talent_pool_with_stars: number
    occ2010: number
    date_last_processed_from?: string
    date_last_processed_to?: string
    posted: number
    title_edu_postings_ba_plus: number
    soc5_list: string
}

type RolesReviewData = {
    results: ResultItem[]
    page: number
    page_size: number
    total: number
}

type SortItem = {
    fieldName: string
    order: 'asc' | 'desc'
}

const RolesReviewCopy: TableHeaderItem[] = [
    {
        text: 'Role',
        id: 'job_title',
    },
    { text: 'Avg. days open', id: 'posted' },
    { text: 'Requirement', id: 'title_edu_postings_ba_plus' },
    { text: 'STAR transitions/yr', id: 'star_transitions', width: '100px', maxWidth: '100px' },
    {
        text: 'Talent increase with STARs',
        id: 'local_increase_in_talent_pool_with_stars',
        width: '145px',
        maxWidth: '145px',
    },
    { text: 'Action plan' },
    { text: 'Jobs status' },
]

export { RolesReviewCopy }
export type { TableHeaderItem, ResultItem, RolesReviewData, SortItem }
