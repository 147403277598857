import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchCohortEmployers } from '../../api/fetchCohortEmployers'
import { Employer } from '../../types/Employer'

export type EmployersResponse = Employer[]

const useCohortsEmployers = (employer_id: string) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery<EmployersResponse>({
        queryKey: ['cohortsEmployers', employer_id],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const params = { employer_id }
            return fetchCohortEmployers(token, params)
        },
        enabled: !!employer_id,
    })
}

export default useCohortsEmployers
