import React from 'react'
import { createTheme } from '@mui/material/styles'
import '../fonts.scss'
import styles from './theme.module.scss'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        tableHeader: React.CSSProperties
        bodyBold: React.CSSProperties
        mini: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        tableHeader?: React.CSSProperties
        bodyBold?: React.CSSProperties
        mini?: React.CSSProperties
    }

    interface Palette {
        yellow: Palette['primary']
        neptune: Palette['primary']
    }

    interface PaletteOptions {
        yellow: PaletteOptions['primary']
        neptune: PaletteOptions['primary']
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        tableHeader: true
        bodyBold: true
        mini: true
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        primaryOpen: true
        primaryOutlined: true
        primaryContained: true
        cancelOpen: true
        cancelContained: true
    }
}

const theme = createTheme({
    palette: {
        primary: {
            light: styles.light,
            main: styles.main,
            dark: styles.dark,
        },
        info: {
            main: styles.info,
        },
        background: {
            default: styles.background,
            paper: styles.white, // Card colors
        },
        yellow: {
            main: styles.yellow,
        },
        neptune: {
            main: styles.neptune,
        },
        success: {
            main: styles.link,
        },
    },
    typography: {
        fontFamily: 'DM Sans, "Euclid Circular B"',
        h1: {
            fontFamily: 'DM Sans',
            fontWeight: 500,
            fontSize: 64,
            lineHeight: '72px',
            color: styles.typography,
        },
        h2: {
            fontWeight: 700,
            fontSize: 48,
            lineHeight: 1.17,
            letterSpacing: -0.5,
            color: styles.typography,
        },
        h3: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 600,
            fontSize: 40,
            color: styles.typography,
        },
        h4: {
            fontWeight: 500,
            fontSize: 32,
            lineHeight: 1.25,
            color: styles.typography,
        },
        h5: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 600,
            fontSize: 24,
            color: styles.typography,
        },
        h6: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 400,
            fontSize: 21,
        },
        tableHeader: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: '16px',
            color: styles.typography,
        },
        body2: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '20px',
            color: styles.typography,
        },
        button: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 600,
            fontSize: 16,
            textTransform: 'none',
        },
        caption: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '16px',
            color: 'inherit',
        },
        bodyBold: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '20px',
            color: 'inherit',
        },
        mini: {
            fontFamily: 'Euclid Circular B',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '16px',
            color: 'inherit',
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    maxWidth: '650px',
                    backgroundColor: styles.colorNeutral900,
                    fontSize: '16px',
                },
                arrow: {
                    '&::before': {
                        backgroundColor: styles.colorNeutral900,
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Euclid Circular B',
                    color: styles.colorNeutral900,
                    fontWeight: 600,
                    fontSize: 14,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    '&:hover': {
                        background: styles.primaryHover,
                    },
                },
            },
        },
    },
})

export default theme
