import React, { useRef, useState } from 'react'
import { Box, Grid, Typography, Chip, Card, Button, LinearProgress } from '@mui/material'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import BottomNavigation from '../BottomNavigation/BottomNavigation'
import html2canvas from 'html2canvas'
import styles from '../JobDetailsPopover.module.scss'
import JobDetailsPopoverStep5 from '../JobDetailsPopoverStep5/JobDetailsPopoverStep5'
import useSpiderChartData, { ApiResponse } from '../../../hooks/query/useSpiderChartData'
import { OawSpiderChart } from '../../Charts/OwaSpiderChart'
import { capitalizeWords } from '../../../utils/format'
import { JobTitleDetailsStep3Copy } from '../../../assets/copies/job_title_details_copy'
import { JobTitlesItem } from '../../../types/JobDetails'

interface JobDetailsPopoverStep4Props {
    item?: JobTitlesItem
    handleClickLeft: () => void
    handleClosePopover: () => void
    screenshots: string[]
}

const JobDetailsPopoverStep4: React.FC<JobDetailsPopoverStep4Props> = ({
    item,
    handleClickLeft,
    handleClosePopover,
    screenshots,
}) => {
    const [clickedRight, setClickedRight] = useState(false)
    const [exportedScreenshots, setExportedScreenshots] = useState<string[]>(screenshots)
    const popoverRef = useRef<HTMLDivElement | null>(null)
    const sourceJobInsightsParams = {
        employer_id: String(item?.employer_id) || '',
        occ2010: item?.occ2010,
        msa_code: item?.msa_code,
        job_title: item?.job_title,
        date_last_processed_to: item?.date_last_processed_to || 'null',
        date_last_processed_from: item?.date_last_processed_from || 'null',
    }
    const { data: dataSpider, isLoading, isFetching } = useSpiderChartData(sourceJobInsightsParams)

    const transformDataForRadarCharts = (response: ApiResponse, destinationJob: string) => {
        return response.map((item) => {
            const skills = Array.from(new Set(item.skills.map((skill) => skill.skill)))
            const data = skills.map((skill) => {
                const skillData: { skill: string; [key: string]: number | string } = { skill }
                const skillInfo = item.skills.find((s) => s.skill === skill)
                if (skillInfo) {
                    skillData[item.source_title] = skillInfo.occ_from_skill_score
                    skillData[destinationJob] = skillInfo.occ_to_skill_score
                }
                return skillData
            })
            const keys = [item.source_title, destinationJob]
            return { data, keys, sourceTitle: item.source_title }
        })
    }
    const destinationJob = item?.job_title || 'Destination Job'

    const chartsData = dataSpider ? transformDataForRadarCharts(dataSpider, destinationJob) : []

    const captureScreenshot = async () => {
        if (popoverRef.current) {
            const screenshot = await html2canvas(popoverRef.current)
            const screenshotDataUrl = screenshot.toDataURL('image/png')
            setExportedScreenshots([...exportedScreenshots, screenshotDataUrl])
        }
    }

    const handleClickRight = () => {
        setClickedRight(true)
        captureScreenshot()
    }

    const handleClickLeftNew = () => {
        setClickedRight(false)
    }

    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        employer_name: item?.employer_name,
    }

    const JobDetailsPopoverStep4Content = () => {
        return (
            <Box className={styles.jobDetailsStep1Main}>
                <div ref={popoverRef}>
                    <Box className={styles.titleBox}>
                        <Box className={styles.title}>
                            <Chip
                                className={styles.chip}
                                label={
                                    <Typography variant="button" color="inherit">
                                        4 of 5
                                    </Typography>
                                }
                            />
                            <Typography variant="body2" fontWeight="bold" color="inherit">
                                {JobTitleDetailsStep3Copy(params).title}
                            </Typography>
                        </Box>
                        <Button className={styles.button} onClick={handleClosePopover} data-testid="close">
                            <HighlightOffOutlinedIcon fontSize="large" />
                        </Button>
                    </Box>
                    <Typography variant="h3" color="white">
                        Skills from these jobs can prepare STARs well
                    </Typography>

                    <Card className={styles.card}>
                        <Box className={styles.spiderChartCard}>
                            <Box className={styles.title}>
                                <Chip
                                    className={styles.greenBackground}
                                    label={
                                        <Typography variant="h5" color="inherit">
                                            {destinationJob}
                                        </Typography>
                                    }
                                />
                                <Typography variant="h6" fontWeight="bold" color="inherit">
                                    skills overlap with…
                                </Typography>
                            </Box>
                            {isLoading || isFetching ? (
                                <LinearProgress color="secondary" />
                            ) : (
                                <Grid container spacing={2} sx={{ height: '100%' }}>
                                    {chartsData && chartsData.length && chartsData.length > 0 ? (
                                        chartsData.map((chartData, index) => (
                                            <Grid item xs={12} sm={6} md={6} key={index}>
                                                <Box className={styles.gridItem}>
                                                    <Chip
                                                        className={styles.jobTitleChip}
                                                        label={
                                                            <Typography variant="button" color="inherit">
                                                                {capitalizeWords(chartData.sourceTitle)}
                                                            </Typography>
                                                        }
                                                    />
                                                    <OawSpiderChart
                                                        data={chartData.data}
                                                        keys={chartData.keys}
                                                        indexBy="skill"
                                                        colors={['#FFB811', '#55AE31']}
                                                        isInteractive={false}
                                                        showLegends={false}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography variant="h6" fontWeight="bold" color="inherit">
                                            No data to display
                                        </Typography>
                                    )}
                                </Grid>
                            )}
                        </Box>
                    </Card>
                </div>
                <BottomNavigation
                    text="Proceed to the next step"
                    job_title_id={item?.id}
                    action="Next"
                    handleClickLeft={handleClickLeft}
                    handleClickRight={handleClickRight}
                />
            </Box>
        )
    }

    return !clickedRight ? (
        <JobDetailsPopoverStep4Content />
    ) : (
        <JobDetailsPopoverStep5
            item={item}
            handleClickLeft={handleClickLeftNew}
            handleClosePopover={handleClosePopover}
            screenshots={exportedScreenshots}
        />
    )
}

export default JobDetailsPopoverStep4
