import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Link, Typography } from '@mui/material'
import Icons from '../../assets/svgs'
import styles from './Footer.module.scss'

const Footer = () => {
    const { isAuthenticated } = useAuth0()

    return (
        <Box component="footer" className={styles.footer}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                paddingY={2}
                borderBottom={1}
                borderTop={1}
                gap={2}
            >
                {/* Logo and social media icons */}
                <Box display="flex" sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between">
                    <Link href="https://opportunityatwork.org">
                        {' '}
                        <Icons.FooterLogo style={{ height: '50px' }} />
                    </Link>

                    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        <Link
                            href="https://www.linkedin.com/company/opportunity-work"
                            target="_blank"
                            className={styles.footerLink}
                        >
                            <Icons.LinkedInIcon className={styles.footerIcon} />
                        </Link>
                        <Link
                            href="https://www.facebook.com/opportunityatwork"
                            target="_blank"
                            className={styles.footerLink}
                        >
                            <Icons.FacebookIcon />
                        </Link>
                        <Link href="https://twitter.com/OpptyatWork" target="_blank" className={styles.footerLink}>
                            <Icons.TwitterIcon />
                        </Link>
                    </Box>
                </Box>

                {/* Links */}
                <Box display="flex" sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        <Link href="#" className={styles.footerLink}>
                            Privacy Policy
                        </Link>
                        <Link href="#" className={styles.footerLink}>
                            Terms of Service
                        </Link>
                        <Link href="mailto:employer_portal_support@stellarsight.org" className={styles.footerLink}>
                            Email Us
                        </Link>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        {isAuthenticated && (
                            <Link href="#" className={styles.footerLink}>
                                Data sources and info
                            </Link>
                        )}
                        <Typography sx={{ color: '#C6C6C6' }}>©2024 Opportunity at Work</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer
