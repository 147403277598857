import React from 'react'
import { ResponsiveRadar, GridLabelProps } from '@nivo/radar'
import styles from './SpiderChart.module.scss'

interface OawSpiderChartProps {
    data: {
        skill: string
        [key: string]: number | string
    }[]
    keys: string[]
    indexBy: string
    colors?: string[]
    dotSize?: number
    className?: string
    isInteractive?: boolean
    showLegends?: boolean
    labelFontFamily?: string
    labelFontSize?: number
    maxLabelWidth?: number
}

const splitLabel = (label: string, maxWidth: number): string[] => {
    const words = label ? label.split(' ') : []
    const lines: string[] = []
    let currentLine = ''

    words.forEach((word) => {
        if ((currentLine + word).length > maxWidth) {
            lines.push(currentLine)
            currentLine = word
        } else {
            currentLine = currentLine ? `${currentLine} ${word}` : word
        }
    })

    lines.push(currentLine)

    return lines
}

const gridLabelFunction = (d: GridLabelProps, maxWidth: number) => {
    const lines = splitLabel(d.id, maxWidth)
    const yOffset = (lines.length - 1) * -0.6
    const labelLength = Math.max(...lines.map((line) => line.length))
    const distance = 1.1 + (labelLength / maxWidth) * 0.1
    return (
        <text
            transform={`translate(${d.x * distance}, ${d.y})`}
            dominantBaseline="central"
            textAnchor="middle"
            style={{ fontSize: 12, fill: '#333' }}
        >
            {lines.map((line, i) => (
                <tspan x={0} dy={i === 0 ? `${yOffset}em` : '1.2em'} key={i}>
                    {line}
                </tspan>
            ))}
        </text>
    )
}

export const OawSpiderChart: React.FC<OawSpiderChartProps> = ({
    data = [],
    keys = [],
    indexBy = '',
    colors = ['#111111', '#222222'],
    dotSize = 6,
    className = styles.customRadarChart,
    isInteractive = true,
    showLegends = true,
    labelFontFamily = 'Euclid Circular B',
    maxLabelWidth = 20,
}) => (
    <div className={className}>
        <ResponsiveRadar
            data={data}
            keys={keys}
            indexBy={indexBy}
            valueFormat=" >-.2f"
            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
            borderColor={{ from: 'color' }}
            gridShape="linear"
            dotSize={dotSize}
            dotColor={{ from: 'color', modifiers: [] }}
            dotBorderWidth={2}
            colors={colors}
            blendMode="multiply"
            motionConfig="wobbly"
            gridLabel={(d) => gridLabelFunction(d, maxLabelWidth)}
            isInteractive={isInteractive}
            legends={
                showLegends
                    ? [
                          {
                              anchor: 'top-left',
                              direction: 'column',
                              translateX: -50,
                              translateY: -40,
                              itemWidth: 80,
                              itemHeight: 20,
                              itemTextColor: '#999',
                              symbolSize: 12,
                              symbolShape: 'circle',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemTextColor: '#000',
                                      },
                                  },
                              ],
                          },
                      ]
                    : []
            }
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontFamily: labelFontFamily,
                            fontSize: 12,
                        },
                    },
                },
                legends: {
                    text: {
                        fontFamily: labelFontFamily,
                    },
                },
            }}
        />
    </div>
)
