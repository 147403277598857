import { ReactNode } from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

type Props = {
    children: ReactNode
}

const Layout = ({ children }: Props) => {
    return (
        <>
            <Header /> {children}
            <Footer />
        </>
    )
}

export default Layout
