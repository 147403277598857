import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchRolesReview } from '../../api/fetchRolesReview'

type Params = {
    employer_id: string
    page?: number
    page_size?: number
    sort?: string
    order?: string
    search?: string
}

const useRolesReviewData = (params: Params) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery({
        queryKey: ['rolesReview', params],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            return fetchRolesReview(token, params)
        },
        enabled: !!params.employer_id,
    })
}

export default useRolesReviewData
