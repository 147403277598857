import { useAuth0 } from '@auth0/auth0-react'
import { Typography, Box } from '@mui/material'
import Icons from '../../assets/svgs'
import styles from './UserProfileBanner.module.scss'

function UserProfileBanner() {
    const { user } = useAuth0()

    const employer_name = localStorage.getItem('employer_name')

    return (
        <Box sx={{ display: 'flex' }} className={styles.container}>
            <div id="banner-name" className={styles.banner}>
                <Icons.UserIcon className={styles.icon} />
                <Typography variant="body2" className={styles.labelContent}>
                    Welcome,&nbsp;
                    <strong>{user?.ep_dev_first_name}</strong>
                </Typography>
            </div>
            <div id="banner-employer" className={styles.banner}>
                <Icons.CompanyIcon className={styles.icon} />
                <Typography variant="body2" className={styles.labelContent}>
                    <strong>{employer_name}</strong>
                </Typography>
            </div>
        </Box>
    )
}

export default UserProfileBanner
