import { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Box, Button, Card, Chip, Divider, LinearProgress, Typography } from '@mui/material'
import html2canvas from 'html2canvas'
import { fetchSourceJobInsights } from '../../../api/fetchSourceJobInsights'
import { JobTitleDetailsStep3Copy } from '../../../assets/copies/job_title_details_copy'
import Icons from '../../../assets/svgs'
import { capitalizeFirst, formatNumber, splitStringIntoArrays } from '../../../utils/format'
import BarChart from '../../Charts/BarChart'
import BottomNavigation from '../BottomNavigation/BottomNavigation'
import styles from '../JobDetailsPopover.module.scss'
import JobDetailsPopoverStep4 from '../JobDetailsPopoverStep4/JobDetailsPopoverStep4'
import { JobTitlesItem } from '../../../types/JobDetails'

type SkillMatchItem = {
    sourceJobProfile: string
    occ2010: number
    pctSkillMatch: number
}

type TransitionsItem = {
    sourceJobProfile: string
    occ2010: number
    transitionCount: number
}

type StarsItem = {
    sourceJobProfile: string
    occ2010: number
    starsInJobProfile: number
}

type SourceJobs = {
    sourceJobsByPctSkillMatch: SkillMatchItem[]
    sourceJobsByTransitions: TransitionsItem[]
    sourceJobsByStars: StarsItem[]
}

const JobDetailsPopoverStep3 = ({
    item,
    handleClickLeft,
    handleClosePopover,
    screenshots,
}: {
    item?: JobTitlesItem
    handleClickLeft: () => void
    handleClosePopover: () => void
    screenshots: string[]
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [clickedRight, setClickedRight] = useState(false)
    const [sourceJobInsights, setSourceJobInsights] = useState<SourceJobs>()

    // export functionality
    const [exportedScreenshots, setExportedScreenshots] = useState<string[]>(screenshots)
    const popoverRef = useRef(null)

    // Function to capture a screenshot and add it to the exportedScreenshots array
    const captureScreenshot = async () => {
        if (popoverRef.current) {
            const screenshot = await html2canvas(popoverRef.current)
            const screenshotDataUrl = screenshot.toDataURL('image/png')
            setExportedScreenshots([...exportedScreenshots, screenshotDataUrl])
        }
    }

    useEffect(() => {
        setExportedScreenshots(screenshots)
    }, [screenshots])

    // fetch sourceJobInsights
    useEffect(() => {
        const getSourceJobInsights = async () => {
            const sourceJobInsightsParams = {
                occ2010: item?.occ2010,
                job_title: item?.job_title,
                employer_id: String(item?.employer_id) || '',
                msa_code: item?.msa_code,
                date_last_processed_to: item?.date_last_processed_to || 'null',
                date_last_processed_from: item?.date_last_processed_from || 'null',
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedData = await fetchSourceJobInsights(token, sourceJobInsightsParams)
            setSourceJobInsights(fetchedData)
        }
        getSourceJobInsights()
    }, [getAccessTokenSilently])

    // navigation
    const handleClickRight = () => {
        setClickedRight(true)
        captureScreenshot()
    }

    const handleClickLeftNew = () => {
        setClickedRight(false)
    }

    const params = {
        job_title: item?.job_title,
        msa_title: item?.msa_short_title,
        employer_name: item?.employer_name,
    }

    const Info = () => {
        return (
            <Box className={styles.info}>
                <Icons.InfoIcon />
                <Typography variant="caption">{JobTitleDetailsStep3Copy(params).info}</Typography>
            </Box>
        )
    }

    const transformSkillData = (barData: SkillMatchItem[]) => {
        return barData.map((item) => {
            const label = splitStringIntoArrays(capitalizeFirst(item.sourceJobProfile))

            return {
                label: label,
                data: Number(item.pctSkillMatch),
            }
        })
    }

    const transformTransitionsData = (barData: TransitionsItem[]) => {
        return barData.map((item) => {
            const label = splitStringIntoArrays(capitalizeFirst(item.sourceJobProfile))
            label[label.length - 1] += ` ${formatNumber(item.transitionCount)}`
            return {
                label: label,
                data: Number(item.transitionCount),
            }
        })
    }

    const StarsMetrics = () => {
        return (
            <Box>
                <Box className={styles.rightData}>
                    <Typography fontWeight="bold" variant="body2">
                        {JobTitleDetailsStep3Copy(params).data3}
                    </Typography>
                    <Divider />
                    {!sourceJobInsights ? (
                        <LinearProgress color="secondary" />
                    ) : (
                        sourceJobInsights?.sourceJobsByStars.map((data) => (
                            <Box className={styles.starsMetrics} key={data.sourceJobProfile}>
                                <Typography variant="body2">{capitalizeFirst(data.sourceJobProfile)}</Typography>
                                <Typography variant="body2" fontWeight="bold">
                                    {formatNumber(data.starsInJobProfile)}
                                </Typography>
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        )
    }

    const JobDetailsPopoverStep3Content = () => {
        return (
            <Box className={styles.jobDetailsStep1Main}>
                <div ref={popoverRef}>
                    <Box className={styles.titleBox}>
                        <Box className={styles.title}>
                            <Chip
                                className={styles.chip}
                                label={
                                    <Typography variant="button" color="inherit">
                                        3 of 4
                                    </Typography>
                                }
                            />
                            <Typography variant="body2" fontWeight="bold" color="inherit">
                                {JobTitleDetailsStep3Copy(params).title}
                            </Typography>
                        </Box>
                        <Button className={styles.button} onClick={handleClosePopover} data-testid="close">
                            <HighlightOffOutlinedIcon fontSize="large" />
                        </Button>
                    </Box>
                    <Typography variant="h3" color="white">
                        {JobTitleDetailsStep3Copy(params).subtitle}
                    </Typography>
                    <Card className={styles.card}>
                        <Box className={styles.leftData}>
                            <Typography variant="body2">{JobTitleDetailsStep3Copy(params).cardHeader}</Typography>
                            <Typography fontWeight="bold" variant="body2">
                                {JobTitleDetailsStep3Copy(params).data1}
                            </Typography>
                            <Divider />
                            <Box height={250}>
                                {!sourceJobInsights ? (
                                    <LinearProgress color="secondary" />
                                ) : (
                                    <BarChart
                                        inputData={transformSkillData(
                                            sourceJobInsights.sourceJobsByPctSkillMatch || []
                                        )}
                                        title={JobTitleDetailsStep3Copy(params).data1}
                                        color="purple"
                                        xLabel={'Skills match'}
                                        disableTooltip
                                        disableXLabel
                                    />
                                )}
                            </Box>
                            <Typography fontWeight="bold" variant="body2">
                                {JobTitleDetailsStep3Copy(params).data2}
                            </Typography>
                            <Divider />
                            <Box height={250}>
                                {!sourceJobInsights ? (
                                    <LinearProgress color="secondary" />
                                ) : (
                                    <BarChart
                                        inputData={transformTransitionsData(
                                            sourceJobInsights.sourceJobsByTransitions || []
                                        )}
                                        title={JobTitleDetailsStep3Copy(params).data2}
                                        color="purple"
                                        xLabel={'Number of annual US transitions'}
                                        disableTooltip
                                    />
                                )}
                            </Box>
                            <Info />
                        </Box>
                        <StarsMetrics />
                    </Card>
                </div>
                <BottomNavigation
                    text={JobTitleDetailsStep3Copy(params).footerText}
                    job_title_id={item?.id}
                    action={JobTitleDetailsStep3Copy(params).action}
                    handleClickLeft={handleClickLeft}
                    handleClickRight={handleClickRight}
                />
            </Box>
        )
    }

    return !clickedRight ? (
        <JobDetailsPopoverStep3Content />
    ) : (
        <JobDetailsPopoverStep4
            item={item}
            handleClickLeft={handleClickLeftNew}
            handleClosePopover={handleClosePopover}
            screenshots={exportedScreenshots}
        />
    )
}

export default JobDetailsPopoverStep3
