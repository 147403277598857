import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchJobTitles } from '../../api/fetchJobTitles'

type IParam = {
    employer_id: string
    msa_code?: number | string
    page?: number
    page_size?: number
    sort?: string
    order?: string
    search?: string
}

const useJobTitles = (params: IParam) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery({
        queryKey: ['jobTitles', params],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const safeParams = {
                ...params,
                msa_code: params.msa_code ?? 0,
            }
            return fetchJobTitles(token, safeParams)
        },
        enabled: !!params.employer_id,
    })
}

export default useJobTitles
