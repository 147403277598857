import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
    Box,
    CssBaseline,
    Toolbar,
    Typography,
    Divider,
    Avatar,
    Menu,
    MenuItem,
    Chip,
    AppBar,
    Link,
    useTheme,
    Skeleton,
} from '@mui/material'
import { getNavigationCopy, navigationCopy } from '../../assets/copies/navigation_copy'
import Icons from '../../assets/svgs'
import styles from './Header.module.scss'
import useGTMEvent from '../../hooks/useGTM'
import { useNavigate } from 'react-router-dom'
import { useGetMeta } from '../../hooks/query/useMetaQuery'

type Item = { title: string; subtitle: string; link: string[] }

const Header = () => {
    const { sendEvent } = useGTMEvent()
    const navigate = useNavigate()

    const { isAuthenticated, logout, user } = useAuth0()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
    const [isHovered, setIsHovered] = useState<Item | null>(null)
    const theme = useTheme()

    const { data: meta, isLoading: isMetaLoading } = useGetMeta()

    const handleClickNav = (item: Item) => {
        navigate(item.link[0])
        sendEvent('top_nav_click', { nav_item: item.title.toLowerCase() })
    }

    const handleClickHireSTARS = () => {
        window.location.href = 'https://www.stellarworx.org/'
    }

    const logoutWithRedirect = () => {
        logout({
            returnTo: window.location.origin,
        })
        sendEvent('top_nav_click', { nav_item: 'logout' })
        sendEvent('logout')
        localStorage.clear()
    }

    const handleMouseEnter = (item: Item) => {
        setIsHovered(item)
    }

    const handleMouseLeave = () => {
        setIsHovered(null)
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorElUser?.contains(event.currentTarget)) {
            handleCloseUserMenu()
        } else {
            setAnchorElUser(event.currentTarget)
        }
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const adjustedNavigationCopy = getNavigationCopy(user || {})

    const profileIcon = isAuthenticated && (
        <Box className={styles.profileIcon}>
            <Box className={styles.keyboardArrowDownIcon}>
                <KeyboardArrowDownIcon className={styles.keyboardArrowDownIcon} />
            </Box>
            <Avatar alt="User Avatar" src={user?.ep_dev_profile_photo_url} sx={{ width: 24, height: 24 }} />
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableScrollLock
            >
                <div className={styles.userSettingMenu}>
                    <MenuItem
                        key="My Profile"
                        component="a"
                        href="/account-settings"
                        onClick={() => sendEvent('top_nav_click', { nav_item: 'my profile' })}
                    >
                        <Typography textAlign="center" variant="body2">
                            My Profile
                        </Typography>
                    </MenuItem>
                </div>
                {localStorage.getItem('test_mode') === 'true' && (
                    <div className={styles.userSettingMenu}>
                        <MenuItem key="Test Settings" component="a" href="/test-settings">
                            <Typography textAlign="center" variant="body2">
                                Test Settings
                            </Typography>
                        </MenuItem>
                    </div>
                )}
                <Divider variant="middle" />
                <div className={styles.userSettingMenu}>
                    <MenuItem key="Log Out" onClick={logoutWithRedirect}>
                        <Typography textAlign="center" variant="body2">
                            Log Out
                        </Typography>
                    </MenuItem>
                </div>
            </Menu>
        </Box>
    )

    return (
        <>
            <AppBar className={styles.topBar}>
                <CssBaseline />
                <Toolbar className={styles.navigation}>
                    <Box className={styles.navigationContent}>
                        <Box className={styles.logo}>
                            <Icons.EmployerPortalLogo
                                onClick={() => sendEvent('top_nav_click', { nav_item: 'logo' })}
                            />

                            <Icons.BetaIcon />
                        </Box>
                        <Box className={styles.chipContainer}>
                            <Typography
                                variant="mini"
                                sx={{
                                    color: 'black',
                                    borderRight: 1,
                                    paddingRight: '10px',
                                    borderColor: theme.palette.primary.main,
                                }}
                            >
                                {meta?.employer_name}
                            </Typography>
                            <Chip
                                label={
                                    <Link
                                        href={'https://www.stellarworx.org/'}
                                        target="_blank"
                                        onClick={() => sendEvent('top_nav_click', { nav_item: 'hire stars' })}
                                    >
                                        <Typography variant="bodyBold" sx={{ color: styles.linkText }}>
                                            Hire STARS
                                        </Typography>
                                    </Link>
                                }
                                variant="outlined"
                                className={styles.chip}
                                clickable
                            />
                            <Chip
                                className={styles.profileIconChip}
                                label={profileIcon}
                                variant="outlined"
                                onClick={handleOpenUserMenu}
                            />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar className={styles.subNavigation}>
                <CssBaseline />
                <Box className={styles.subNavigationContent}>
                    {adjustedNavigationCopy.map((item) => (
                        <Box
                            key={item.link[0]}
                            className={`${styles.navSection} ${
                                item.link.includes(window.location.pathname) && styles.active
                            }`}
                            onClick={() => handleClickNav(item)}
                            onMouseEnter={() => handleMouseEnter(item)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Box className={styles.title}>
                                <Typography variant="button">{item.title}</Typography>

                                {isMetaLoading ? (
                                    <Skeleton variant="circular" width={24} height={24} />
                                ) : isHovered === item && !item.link.includes(window.location.pathname) ? (
                                    <Box className={styles.headerChipContainer}>
                                        <Chip
                                            size="small"
                                            label={
                                                <Typography variant="caption" fontWeight="bold">
                                                    {meta && meta[item.key] ? meta[item.key] : 0}
                                                </Typography>
                                            }
                                            className={styles.headerChipHover}
                                        />
                                        <Typography className={styles.arrow} fontWeight="bold">
                                            &rarr;
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Chip
                                        size="small"
                                        label={
                                            <Typography variant="caption" fontWeight="bold">
                                                {meta && meta[item.key] ? meta[item.key] : 0}
                                            </Typography>
                                        }
                                        className={
                                            item.link.includes(window.location.pathname) ? styles.headerChipActive : ''
                                        }
                                    />
                                )}
                            </Box>
                            <Typography variant="body2">{item.subtitle}</Typography>
                        </Box>
                    ))}
                </Box>
            </Toolbar>
        </>
    )
}

export default Header
