import { Typography, Box } from '@mui/material'
import { AccountSettingsCopy } from '../../../assets/copies/account_settings_copy'
import styles from './Privacy.module.scss'

function Privacy() {
    return (
        <div>
            <Box className={styles.cardHeader}>
                <Typography variant="h5">{AccountSettingsCopy.PrivacyTitle}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }} className={styles.cardSubtitle}>
                <Typography variant="body2">{AccountSettingsCopy.PrivacySubtitle}</Typography>
            </Box>
        </div>
    )
}

export default Privacy
