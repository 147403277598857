import axios from 'axios'
import { formatNumber } from '../utils/format'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    occ2010?: number
    job_title?: string
    employer_id?: string
    msa_code?: number
    date_last_processed_from: string
    date_last_processed_to: string
}

export const fetchTalentPoolInsights = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/star-potential', config)
        const reshapedData = {
            starsInTalentPool: Math.round(response?.data.starsInTalentPool || 0),
            increaseInTalentPoolWithStars: Math.round(response?.data.increaseInTalentPoolWithStars || 0),
            totalUniquePostings: response?.data.totalUniquePostings || 0,
            starsInJobProfile: formatNumber(response?.data.starsInJobProfile || 0),
            eduPostingsBaPlus: response?.data.eduPostingsBaPlus || 0,
            starsTransitions: formatNumber(response?.data.starsTransitions * 1000),
        }
        return reshapedData
    } catch (error) {
        console.log(error)
    }
}
