import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    job_title_id?: string | number
    employer_id: string
}

export const fetchSavedActions = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/get-saved-actions', config)
        return response?.data.results
    } catch (error) {
        console.log(error)
    }
}
