const ArchiveCopy = {
    header: `This role was...`,
    subheader: `Select option`,
    reasons: [
        {
            text: `Filled by a STAR`,
            subtext: `Made a skills-based hire`,
        },
        {
            text: `Filled by a non-STAR`,
            subtext: `Hired somebody with a Bachelor's degree`,
        },
        {
            text: `Closed`,
            subtext: `No longer hiring`,
        },
        {
            text: `Not fit for STARs`,
            subtext: `Remove from Roles Review`,
        },
    ],
    removeHeader: `Role removed from Review.`,
    removeReasons: [`Not a good fit for STARs`, `Added role to review by accident`, `Another reason`],
    ending: `Thanks. This role was archived.`,
}

export { ArchiveCopy }
