import axios from 'axios'
import { formatTimestamp } from '../utils/format'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    employer_id?: string
    cohort_id?: string
}

export const fetchMeta = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params, cohort_id: params?.cohort_id === '' ? null : params?.cohort_id },
        }
        const response = await axios.get(api_url + '/employers/get-meta', config)
        return {
            ...response?.data,
            cohort_date_last_processed: formatTimestamp(response?.data.cohort_date_last_processed),
            employer_date_last_processed: formatTimestamp(response?.data.employer_date_last_processed),
        }
    } catch (error) {
        console.log(error)
    }
}
