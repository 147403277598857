import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography, Chip, Button } from '@mui/material'
import { addRoleToReview } from '../../../api/addRoleToReview'
import { fetchSavedActions } from '../../../api/fetchSavedActions'
import Icons from '../../../assets/svgs'
import ActionPlanToast from '../ActionPlanToast/ActionPlanToast'
import styles from '../JobDetailsPopover.module.scss'

const BottomNavigation = ({
    text,
    job_title_id,
    action,
    handleClickLeft,
    handleClickRight,
}: {
    text: string
    job_title_id?: number
    action: string
    handleClickLeft: () => void
    handleClickRight: () => void
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const [checked, setChecked] = useState(false)
    const [actions, setActions] = useState<string[]>()
    const [actionPlanAdded, setActionPlanAdded] = useState(false)
    const employer_id = localStorage.getItem('employer_id') || ''

    const handleClose = () => {
        setActionPlanAdded(false)
    }
    // fetch actions
    useEffect(() => {
        const getActions = async () => {
            const params = {
                job_title_id: job_title_id,
                employer_id: employer_id,
            }
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const fetchedActions = await fetchSavedActions(token, params)
            setActions(fetchedActions)
            if (fetchedActions?.includes(action)) {
                setChecked(true)
            }
            if (fetchedActions.length > 0) {
                setActionPlanAdded(false)
            }
        }
        getActions()
    }, [getAccessTokenSilently])

    // set actions based on checkbox
    const handleCheckAction = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        let action_operation
        let updatedActions
        if (checked) {
            action_operation = 'remove'
            updatedActions = actions?.filter((item) => item !== action)
            setActions(updatedActions)
        } else {
            action_operation = 'append'
            updatedActions = actions?.concat(action)
            setActions(updatedActions)
        }
        setChecked(!checked)
        const params = { job_title_id: job_title_id, action_operation: action_operation, employer_id: employer_id }
        const data = { actions_selected: [action] }
        addRoleToReview(token, params, data)
        if (action_operation === 'append' && actions?.length == 0) {
            setActionPlanAdded(true)
        }
    }

    return (
        <Box className={styles.hover}>
            <Box className={styles.hoverInfo}>
                <Box className={styles.icon}>
                    <Icons.LightningIcon />
                    <Typography variant="body2">
                        <b>Suggestion: </b>
                        {text}
                    </Typography>
                </Box>
                {actions && (
                    <Chip
                        variant={checked ? 'filled' : 'outlined'}
                        icon={<input type="checkbox" checked={checked} id={action} onChange={handleCheckAction} />}
                        label={
                            <Typography variant="body2" fontWeight="bold">
                                Add to action plan
                            </Typography>
                        }
                        style={{ paddingLeft: 5 }}
                        onClick={handleCheckAction}
                    />
                )}
            </Box>
            <Button className={styles.hoverButton} onClick={handleClickLeft}>
                <Icons.ChevronLeftIcon fontSize="large" color="inherit" />
            </Button>
            <Button className={styles.hoverButton} onClick={handleClickRight}>
                <Icons.ChevronRightIcon fontSize="large" color="inherit" />
            </Button>
            {actionPlanAdded && <ActionPlanToast handleClose={handleClose} />}
        </Box>
    )
}
export default BottomNavigation
