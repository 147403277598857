import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { formatNumber } from '../../utils/format'
import styles from './Chart.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type DataItem = {
    label: string | string[]
    data: number
}

const COLORS: { [key: string]: string[] } = {
    green: [
        styles.colorGreen100,
        styles.colorGreen95,
        styles.colorGreen90,
        styles.colorGreen85,
        styles.colorGreen80,
        styles.colorGreen75,
        styles.colorGreen70,
        styles.colorGreen65,
        styles.colorGreen60,
        styles.colorGreen40,
    ],
    purple: [styles.colorPurple100, styles.colorPurple90, styles.colorPurple80, styles.colorPurple60],
    mixed: [
        styles.colorNeutral400,
        styles.colorNeutral900,
        styles.colorBrandGreen,
        styles.colorOrange100,
        styles.colorNeutral700,
    ],
    orange: [
        styles.colorOrange125,
        styles.colorOrange100,
        styles.colorOrange75,
        styles.colorOrange50,
        styles.colorOrange25,
    ],
}

const BarChart = ({
    inputData,
    title,
    color,
    xLabel,
    percent,
    barThickness,
    disableTooltip,
    disableXLabel,
}: {
    inputData: DataItem[]
    title: string
    color: string
    xLabel: string
    percent?: boolean
    barThickness?: number
    disableTooltip?: boolean
    disableXLabel?: boolean
}) => {
    const options = {
        animation: {
            duration: 0,
        },
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: xLabel,
                },

                ticks: {
                    callback: (val: number | string) => (percent ? `${val}%` : formatNumber(Number(val))),
                    maxTicksLimit: 2,
                    display: !disableXLabel,
                },
            },
            y: {
                ticks: {
                    maxTicksLimit: 0,
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: !disableTooltip,
            },
        },
    }
    const chartData = useMemo(() => {
        const labels = inputData.map((item) => item.label)
        return {
            labels,
            datasets: [
                {
                    label: title,
                    data: inputData.map((item) => item.data),
                    backgroundColor: COLORS[color] || [],
                    barThickness: barThickness ? barThickness : 10,
                    borderRadius: 20,
                },
            ],
        }
    }, [inputData])
    return <Bar data-testid={title} options={options} data={chartData} />
}

export default BarChart
