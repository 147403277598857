import { useQuery } from '@tanstack/react-query'
import { fetchMeta } from '../../api/fetchMeta'
import { useAuth0 } from '@auth0/auth0-react'
import { MetaData } from '../../types/MetaData'

export const useGetMeta = () => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery<MetaData>({
        queryKey: ['meta'],
        queryFn: async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
                scope: 'offline_access',
            })
            const params = {
                employer_id: localStorage.getItem('employer_id') || '',
                cohort_id: localStorage.getItem('cohort_id') || '',
            }

            return await fetchMeta(token, params)
        },
    })
}
