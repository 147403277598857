import { Typography, Box } from '@mui/material'
import { AccountSettingsCopy } from '../../../assets/copies/account_settings_copy'
import styles from './TermsConditions.module.scss'

function TermsConditions() {
    return (
        <div>
            <Box sx={{ display: 'flex' }} className={styles.cardHeader}>
                <Typography variant="h5">{AccountSettingsCopy.TermsConditionsTitle}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }} className={styles.cardSubtitle}>
                <Typography variant="body2">{AccountSettingsCopy.TermsConditionsSubtitle}</Typography>
            </Box>
        </div>
    )
}

export default TermsConditions
