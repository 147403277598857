import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@mui/material'
import { cohortAdminHeader, jobTitlesHeader } from '../../../assets/copies/header_copy'
import Icons from '../../../assets/svgs'
import styles from './Subheader.module.scss'
import { useGetMeta } from '../../../hooks/query/useMetaQuery'

const JobTitlesSubheader = () => {
    const { user } = useAuth0()

    const { data: meta } = useGetMeta()

    const params = {
        name: user?.ep_dev_first_name,
        numPositions: meta?.num_open_jobs,
    }

    return (
        <>
            <Box className={styles.subheader}>
                <Icons.JobDetailsGraphic className={styles.graphic} />
            </Box>

            <Box className={styles.text}>
                <Box className={styles.headerText}>
                    <Typography variant="h3" color="inherit">
                        {jobTitlesHeader(params).greeting}
                    </Typography>
                    <Typography variant="h3" color="inherit">
                        {user?.ep_dev_roles?.includes('Cohort Admin')
                            ? cohortAdminHeader({ numPositions: meta?.num_cohort_open_jobs }).header
                            : jobTitlesHeader(params).header}
                    </Typography>
                </Box>
                <Box className={styles.subheaderText}>
                    <Typography variant="h6">
                        {' '}
                        {user?.ep_dev_roles?.includes('Cohort Admin')
                            ? cohortAdminHeader({ numPositions: meta?.num_cohort_open_jobs }).subheader
                            : jobTitlesHeader(params).subheader}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default JobTitlesSubheader
