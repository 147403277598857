const jobTitlesHeader = ({ name, numPositions }: { name: string; numPositions?: number }) => ({
    greeting: `Hello, ${name}.`,
    header: `STARs could succeed in ${numPositions || 0} roles at your company.`,
    subheader: `Scan the table below and add to review any role that may be a good fit for skills-based hiring.`,
})

const rolesReviewHeader = ({ numRoles }: { numRoles?: number }) => ({
    header: `Let\u2019s review the ${numRoles || 0} jobs you selected.`,
    subheader: `Click a role below to see details, create action plans, and update the status of jobs to contribute your insights.`,
})

const cohortInsightsHeader = ({ numCompanies, numJobs }: { numCompanies?: number; numJobs?: number }) => ({
    header: `${numCompanies || 0} companies in your cohort are hiring for ${numJobs || 0} jobs right now.`,
    subheader: `Review the insights below to see how your employer has contributed to the collective progress of the cohort. Be sure to submit your employer’s skills-based hiring stories so others can learn from your success.`,
})

const cohortAdminHeader = ({ numPositions }: { numPositions?: number }) => ({
    header: `STARs could succeed in ${numPositions || 0} open jobs in your cohort.`,
    subheader: `Select one or more companies and scan the table to add or review any role that may be a good fit for skills-based hiring.`,
})

export { jobTitlesHeader, rolesReviewHeader, cohortInsightsHeader, cohortAdminHeader }
