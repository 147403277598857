import React, { ComponentType, useEffect } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { fetchCohorts } from '../api/fetchCohorts'
import useRefreshToken from '../auth/useRefreshToken'
import ExploreJobsPage from '../views/ExploreJobsPage/ExploreJobsPage'
import Loading from '../views/Loading/Loading'
import TestSettingsPage from '../views/TestSettingsPage/TestSettingsPage'

type IProtectedRouteProps = {
    component: ComponentType
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ component }) => {
    const { isAuthenticated, isLoading, getIdTokenClaims, getAccessTokenSilently } = useAuth0()
    const { refreshAccessToken } = useRefreshToken()

    const checkAndRefreshToken = async () => {
        if (!isLoading && isAuthenticated) {
            const claims = await getIdTokenClaims()
            const exp = claims?.exp ? claims.exp : 0
            const now = Date.now()
            const timeUntilExpiration = exp * 1000 - now

            if (timeUntilExpiration <= 30000) {
                await refreshAccessToken()
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(checkAndRefreshToken, 30000) // Check every 30 secs
        checkAndRefreshToken()
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-loading">
                <Loading />
            </div>
        ),
    })

    const { user } = useAuth0()

    const getCohorts = async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
            scope: 'offline_access',
        })
        const employer_id = { employer_id: localStorage.getItem('employer_id') || '' }
        const cohorts = await fetchCohorts(token, employer_id)
        // defaulting to first cohort in list
        if (cohorts && cohorts.length > 0) {
            localStorage.setItem('cohort_id', cohorts[0].cohort_id)
            localStorage.setItem('cohort_name', cohorts[0].cohort_name)
        }
    }

    if (user) {
        if (!localStorage.getItem('employer_id')) localStorage.setItem('employer_id', user?.ep_dev_employer_id)
        if (!localStorage.getItem('employer_name')) localStorage.setItem('employer_name', user?.ep_dev_organization)
        if (!localStorage.getItem('test_mode'))
            localStorage.setItem('test_mode', user?.ep_dev_roles.includes('EP App Admin'))
        if (!localStorage.getItem('cohort_id')) getCohorts()
    }

    if (component === TestSettingsPage && localStorage.getItem('test_mode') === 'false') {
        return <ExploreJobsPage />
    }

    return <Component />
}
