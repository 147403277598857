import { useCallback } from 'react'

interface GTMEventData {
    [key: string]: string
}

const useGTMEvent = () => {
    const sendEvent = useCallback((event: string, data?: GTMEventData) => {
        if ((window as any).dataLayer) {
            ;(window as any).dataLayer.push({
                event,
                ...data,
            })
        } else {
            console.error('GTM dataLayer is not available')
        }
    }, [])

    return { sendEvent }
}

export default useGTMEvent
