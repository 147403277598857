import axios from 'axios'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

export const fetchFilters = async (token: string, params: { employer_id: string }) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/filters', config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}
