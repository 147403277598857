import axios from 'axios'
import { formatNumber, formatTimestamp } from '../utils/format'
import { createHeaders } from './createHeaders'

const api_url = process.env.REACT_APP_API_URL || ''

type IParam = {
    occ2010?: number
    job_title?: string
    employer_id?: string
    msa_code?: number
    date_last_processed_from: string
    date_last_processed_to: string
}

export const fetchJobTitleDetails = async (token: string, params: IParam) => {
    try {
        const config = {
            headers: createHeaders(token),
            params: { ...params },
        }
        const response = await axios.get(api_url + '/employers/job-titles/details', config)
        const reshapedData = {
            posted: response?.data.posted,
            daysSincePosted: response?.data.daysSincePosted,
            dateLastProcessed: formatTimestamp(response?.data.dateLastProcessed),
            untappedTalent: formatNumber(response?.data.untappedTalent),
            unexploredSourceJobs: response?.data.unexploredSourceJobs,
        }
        return reshapedData
    } catch (error) {
        console.log(error)
    }
}
