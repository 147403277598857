import { useAuth0 } from '@auth0/auth0-react'
import { Typography } from '@mui/material'
import styles from './Loading.module.scss'

function Loading() {
    const { isLoading } = useAuth0()

    return (
        <div className={styles.loading}>
            <Typography variant="h6">{isLoading && 'Loading...'}</Typography>
        </div>
    )
}

export default Loading
