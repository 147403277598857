import { Typography, Box } from '@mui/material'
import { AccountSettingsCopy } from '../../../assets/copies/account_settings_copy'
import styles from './Security.module.scss'

function Security() {
    return (
        <div>
            <Box sx={{ display: 'flex' }} className={styles.cardHeader}>
                <Typography variant="h5">{AccountSettingsCopy.SecurityTitle}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }} className={styles.cardSubtitle}>
                <Typography variant="body2">{AccountSettingsCopy.SecuritySubtitle}</Typography>
            </Box>
        </div>
    )
}

export default Security
