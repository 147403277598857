import { useAuth0 } from '@auth0/auth0-react'

const useRefreshToken = () => {
    const { getAccessTokenSilently } = useAuth0()

    const refreshAccessToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently()
            console.log('Refreshed Access Token:', accessToken)
        } catch (error) {
            console.error('Error refreshing access token:', error)
        }
    }

    return {
        refreshAccessToken,
    }
}

export default useRefreshToken
