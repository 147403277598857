import React, { useEffect } from 'react'
import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { Employer } from '../../types/Employer'

interface EmployerFilterProps {
    employers: Employer[]
    selectedEmployers: number[]
    onChange: (selectedEmployers: number[]) => void
}

const ITEM_HEIGHT = 60
const ITEM_PADDING_TOP = 8
const MENU_WIDTH = 350

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: MENU_WIDTH,
            marginLeft: 55,
        },
    },
}

const EmployerFilter: React.FC<EmployerFilterProps> = ({ employers, selectedEmployers, onChange }) => {
    const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value as number[]

        if (value.includes('all' as unknown as number)) {
            onChange(employers.map((employer) => employer.employer_id))
        } else {
            onChange(value)
        }
    }

    const isAllSelected = selectedEmployers.length === employers.length

    return (
        <Select
            multiple
            displayEmpty
            MenuProps={MenuProps}
            value={isAllSelected ? [] : selectedEmployers}
            onChange={handleSelectChange}
            sx={{ borderRadius: 16, width: 250, height: 40 }}
            renderValue={(selected) => {
                if (isAllSelected) {
                    return 'Show all companies'
                }

                if (selected.length === 0) {
                    return <Typography>Select a company</Typography>
                }

                return selected
                    .map((id) => employers.find((employer) => employer.employer_id === id)?.employer_name)
                    .join(', ')
            }}
        >
            <MenuItem value="all">
                <Checkbox checked={isAllSelected} />
                <ListItemText primary="Show all companies" />
            </MenuItem>
            {employers.map((employer) => (
                <MenuItem key={employer.employer_id} value={employer.employer_id}>
                    <Checkbox checked={selectedEmployers.includes(employer.employer_id) && !isAllSelected} />
                    <ListItemText primary={employer.employer_name} />
                </MenuItem>
            ))}
        </Select>
    )
}

export default EmployerFilter
